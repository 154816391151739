<template>
  <section>
    <base-dialog :show="loading" title="Loading..." :fixed="true">
      <base-spinner></base-spinner>
    </base-dialog>

    <base-dialog :show="!!error" title="Minimum Players" @close="handleError">
      Please ensure there are at least 3 players. Send the 4-letter room code to
      others you'd like to play with.
    </base-dialog>

    <base-dialog
      :show="getGlobalError.flag"
      title="Disconnection"
      @close="handleGlobalError"
      mode="error"
      >{{ getGlobalError.type }}. Please return to the welcome screen to start a
      new game.
      <template v-slot:actions>
        <base-button mode="red" @click="handleGlobalError"
          >Leave Game</base-button
        ></template
      ></base-dialog
    >

    <base-dialog :show="getKicked" title="Kicked" @close="handleKicked">
      Sorry, you were kicked from this room.
      <template v-slot:actions>
        <base-button mode="red" @click="handleKicked"
          >Leave Game</base-button
        ></template
      ></base-dialog
    >

    <div class="container">
      <div class="content">
        <game-invite></game-invite>
        <div class="game-start">
          <base-button v-if="getRoomAdmin" mode="green" @click="startGame"
            ><font-awesome-icon icon="play" /> Start game</base-button
          >
          <p v-else>Please wait for the host to start the game.</p>
        </div>
        <game-options></game-options>
      </div>
      <div class="content lobby-grid">
        <game-players></game-players>
        <game-chat></game-chat>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GameInvite from '../components/game/GameInvite.vue';
import GameOptions from '../components/game/GameOptions.vue';
import GamePlayers from '../components/game/GamePlayers.vue';
import GameChat from '../components/game/GameChat.vue';

export default {
  components: {
    GameInvite,
    GameOptions,
    GamePlayers,
    GameChat
  },
  data() {
    return {
      error: false,
      loading: false
    };
  },
  watch: {
    getGameStage() {
      if (this.getGameStage === 'ingame') {
        this.loading = false;
        this.$router.replace('/room/' + this.getCurrentRoom);
      }
    },
    getKicked() {
      if (this.getKicked === true) {
        this.$socket.client.emit('LEAVE_GAME');
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentRoom',
      'getPlayers',
      'getGameStage',
      'getRoomAdmin',
      'getGlobalError',
      'getKicked'
    ])
  },
  methods: {
    ...mapActions(['setGameStage']),
    startGame() {
      if (this.getPlayers.length >= 3) {
        this.loading = true;
        this.$socket.client.emit('START_GAME', this.getCurrentRoom);
      } else {
        this.error = true;
      }
    },
    setGameStageToLobby() {
      this.setGameStage('lobby');
    },
    handleError() {
      this.error = false;
    },
    handleGlobalError() {
      this.$socket.client.emit('LEAVE_GAME');
      this.$router.replace({ name: 'home' });
    },
    handleKicked() {
      this.$router.replace({ name: 'home' });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'home' || from.name === 'room') {
      next();
    } else {
      next({ name: 'home' });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'room' && !this.getKicked && !this.getGlobalError.flag) {
      const answer = window.confirm('Do you really want to leave the game?');
      if (answer) {
        this.$socket.client.emit('LEAVE_GAME');
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  mounted() {
    this.setGameStageToLobby();
  }
};
</script>

<style scoped>
.container {
  align-items: flex-start;
}

.lobby-grid {
  display: grid;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, minmax(250px, 400px));
  grid-template-areas:
    'players'
    'chat';
}

.game-players-container {
  border-top-left-radius: var(--rounded-corner-small);
}

.game-chat-container {
  border-bottom-left-radius: var(--rounded-corner-small);
}

.game-start p {
  background: var(--dark-700);
  padding: 1rem;
  margin: 0 0 1.25rem 0;
  border-radius: var(--rounded-corner-small);
}

@media (max-width: 768px) {
  .lobby-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(150px, 300px));
    grid-template-areas:
      'players'
      'chat';
  }
}
</style>
