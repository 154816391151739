<template>
  <section>
    <div class="container center">
      <div class="content">
        <h2>Page not found!</h2>
        <p>Sorry about that.</p>
        <p>
          Please try visiting the <router-link to="/">homepage</router-link> to
          reorient yourself.
        </p>
        <p>
          <router-link to="/feedback">Let me know</router-link> if you think
          this was in error.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.center {
  text-align: center;
}
</style>
