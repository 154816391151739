import { createStore } from 'vuex';

const store = createStore({
  modules: {},
  state() {
    return {
      gameStage: 'welcome',
      username: null,
      usernameSelected: false, // has user selected a username
      currentRoom: null, // current room
      roomFound: null, // has user found a room
      roomAdmin: false, // is this player the room creator
      players: [], // all players in the room
      currentWord: [], // current word being drawn in that round
      customWords: [], // custom words set by the host
      useCustomWordsOnly: false, // option to only use custom words
      chatMessages: [], // chat messages
      isNavOpen: false, // is sidebar menu open,
      brushHistory: [], // what's been drawn on the canvas
      brushUndo: [], // temp brush history for undo
      undoLimit: false, // can only undo last drawn line
      brushOneChance: false, // user gets to draw one line
      brush: {
        size: 12,
        colour: '#ffffff'
      },
      votingRoundGrace: 0, // setting for how many rounds before voting starts
      votingRound: false,
      votingTimer: 60,
      votes: [],
      votingFinished: false,
      secretArtistFound: false,
      win: {
        flag: false,
        result: null,
        guess: null
      },
      globalError: {
        flag: false,
        type: null
      },
      kicked: false
    };
  },
  mutations: {
    CURRENT_ROOM(state, room) {
      state.currentRoom = room;
    },
    ROOM_FOUND(state, payload) {
      state.roomFound = payload;
    },
    ROOM_ADMIN(state, payload) {
      state.roomAdmin = payload;
    },
    PLAYERS(state, payload) {
      state.players = payload;
    },
    SET_BRUSH_SIZE(state, brushSize) {
      state.brush.size = brushSize;
    },
    SET_BRUSH_COLOUR(state, brushColour) {
      state.brush.colour = brushColour;
    },
    BRUSH_HISTORY(state, payload) {
      state.brushHistory.push(payload);
    },
    UNDO_BRUSH_HISTORY(state) {
      state.brushHistory.splice(-1, 1);
    },
    BRUSH_UNDO(state, payload) {
      state.brushUndo.push(payload);
    },
    RESET_BRUSH_UNDO(state) {
      state.brushUndo = [];
    },
    CLEAR_BRUSH_HISTORY(state) {
      state.brushHistory = [];
    },
    UNDO_LIMIT(state, payload) {
      state.undoLimit = payload;
    },
    BRUSH_ONE_CHANCE(state, payload) {
      state.brushOneChance = payload;
    },
    CURRENT_WORD(state, payload) {
      state.currentWord = payload;
    },
    CUSTOM_WORDS(state, payload) {
      state.customWords = payload;
    },
    USE_CUSTOM_WORDS_ONLY(state, payload) {
      state.useCustomWordsOnly = payload;
    },
    CHAT_MESSAGES(state, payload) {
      state.chatMessages.push(payload);
    },
    CLEAR_CHAT_MESSAGES(state, payload) {
      state.chatMessages = payload;
    },
    GAME_STAGE(state, payload) {
      state.gameStage = payload;
    },
    VOTING_ROUND_GRACE(state, payload) {
      state.votingRoundGrace = payload;
    },
    MINUS_VOTING_ROUND_GRACE(state) {
      if (state.votingRoundGrace > 1) {
        state.votingRoundGrace--;
      }
    },
    VOTING_ROUND(state, payload) {
      state.votingRound = payload;
    },
    VOTING_FINISHED(state, payload) {
      state.votingFinished = payload;
    },
    VOTING_TIMER(state, payload) {
      state.votingTimer = payload;
    },
    VOTES(state, payload) {
      state.votes.push(payload);
    },
    CLEAR_VOTES(state) {
      state.votes = [];
    },
    SECRET_ARTIST_FOUND(state, payload) {
      state.secretArtistFound = payload;
    },
    USERNAME_SELECTED(state) {
      state.usernameSelected = !state.usernameSelected;
    },
    USERNAME(state, payload) {
      state.username = payload;
    },
    WIN(state, payload) {
      state.win = payload;
    },
    TOGGLE_NAV(state) {
      state.isNavOpen = !state.isNavOpen;
    },
    GLOBAL_ERROR(state, errorMsg) {
      state.globalError = errorMsg;
    },
    KICKED(state, payload) {
      state.kicked = payload;
    }
  },
  actions: {
    socket_createdRoom(context, roomCode) {
      context.commit('ROOM_FOUND', true);
      context.commit('CURRENT_ROOM', roomCode);
      context.commit('ROOM_ADMIN', true);
      context.dispatch('resetGlobalError');
    },
    socket_joinedRoom(context, roomCode) {
      context.commit('ROOM_FOUND', true);
      context.commit('CURRENT_ROOM', roomCode);
      context.dispatch('resetGlobalError');
    },
    socket_leftRoom(context) {
      context.commit('ROOM_FOUND', null);
      context.commit('CURRENT_ROOM', null);
      context.commit('ROOM_ADMIN', false);
      context.commit('CLEAR_CHAT_MESSAGES', []);
    },
    socket_keyPlayerLeftRoom(context, errorMsg) {
      if (errorMsg === 'hostLeftRoom') {
        const globalError = {
          flag: true,
          type: 'Host left the game'
        };
        context.commit('GLOBAL_ERROR', globalError);
      } else if (errorMsg === 'secretArtistLeftRoom') {
        const globalError = {
          flag: true,
          type: 'Secret Artist left the game'
        };
        context.commit('GLOBAL_ERROR', globalError);
      } else if (errorMsg === 'activePlayerLeftRoom') {
        const globalError = {
          flag: true,
          type: 'Active Player left the game'
        };
        context.commit('GLOBAL_ERROR', globalError);
      }
    },
    socket_lessThanThreePlayers(context) {
      const globalError = {
        flag: true,
        type: 'Fewer than 3 players remain'
      };
      context.commit('GLOBAL_ERROR', globalError);
    },
    socket_roomNotFound(context) {
      context.commit('ROOM_FOUND', false);
    },
    socket_roomFull(context) {
      context.commit('ROOM_FOUND', false);
    },
    socket_startGame(context) {
      context.dispatch('setGameStage', 'ingame');
      context.commit('VOTING_ROUND', false);
      context.commit('VOTING_FINISHED', false);
      context.commit('CLEAR_VOTES');
      context.commit('CLEAR_BRUSH_HISTORY');
      context.dispatch('setSecretArtistFound', false);

      const resetWinner = {
        flag: false,
        result: null,
        guess: null
      };
      context.dispatch('setWinner', resetWinner);
      
    },
    socket_updatePlayers(context, playersList) {
      context.commit('PLAYERS', playersList);
    },
    socket_setCurrentWord(context, payload) {
      context.dispatch('setCurrentWord', payload);
    },
    socket_nextPlayerTurn(context, players) {
      context.dispatch('socket_updatePlayers', players);
    },
    socket_endRound(context, payload) {
      context.commit('VOTING_ROUND', payload);
    },
    socket_skipEndRound(context) {
      context.commit('MINUS_VOTING_ROUND_GRACE');
    },
    socket_newRound(context) {
      context.commit('VOTING_ROUND', false);
      context.commit('VOTING_FINISHED', false);
      context.commit('CLEAR_VOTES');
      context.dispatch('setSecretArtistFound', false);
    },
    socket_setVotingTimer(context, payload) {
      context.commit('VOTING_TIMER', payload);
    },
    socket_setVotingGrace(context, payload) {
      context.commit('VOTING_ROUND_GRACE', payload);
    },
    socket_voteCast(context, payload) {
      context.commit('VOTES', payload);
    },
    socket_voteResult(context, payload) {
      context.commit('VOTING_FINISHED', true);
      context.dispatch('setSecretArtistFound', payload);
    },
    socket_winner(context, payload) {
      context.commit('VOTING_ROUND', false);
      context.dispatch('setWinner', payload);
    },
    socket_receiveChat(context, chatData) {
      context.commit('CHAT_MESSAGES', chatData);
    },
    socket_kickPlayer(context) {
      context.commit('KICKED', true);
    },
    resetRoomFound(context) {
      context.commit('ROOM_FOUND', null);
    },
    setBrushSize(context, brushSize) {
      context.commit('SET_BRUSH_SIZE', brushSize);
    },
    setBrushColour(context, brushColour) {
      context.commit('SET_BRUSH_COLOUR', brushColour);
    },
    setBrushHistory(context, payload) {
      context.commit('BRUSH_HISTORY', payload);
    },
    undoBrushHistory(context) {
      context.commit('UNDO_BRUSH_HISTORY');
    },
    setBrushUndo(context, payload) {
      context.commit('BRUSH_UNDO', payload);
    },
    resetBrushUndo(context) {
      context.commit('RESET_BRUSH_UNDO');
    },
    setUndoLimit(context, payload) {
      context.commit('UNDO_LIMIT', payload);
    },
    setBrushOneChance(context, payload) {
      context.commit('BRUSH_ONE_CHANCE', payload);
    },
    setCurrentWord(context, payload) {
      context.commit('CURRENT_WORD', payload);
    },
    setCustomWords(context, payload) {
      context.commit('CUSTOM_WORDS', payload);
    },
    setGameStage(context, payload) {
      context.commit('GAME_STAGE', payload);
    },
    setVotingRoundGrace(context, payload) {
      context.commit('VOTING_ROUND_GRACE', payload);
    },
    setVotingRound(context, payload) {
      context.commit('VOTING_ROUND', payload);
    },
    setVotingTimer(context, payload) {
      context.commit('VOTING_TIMER', payload);
    },
    setSecretArtistFound(context, payload) {
      context.commit('SECRET_ARTIST_FOUND', payload);
    },
    setWinner(context, payload) {
      context.commit('WIN', payload);
    },
    resetGlobalError(context) {
      const resetGlobalError = {
        flag: false,
        type: null
      };
      context.commit('GLOBAL_ERROR', resetGlobalError);
    },
    resetKicked(context) {
      context.commit('KICKED', false);
    }
  },
  getters: {
    getCurrentRoom(state) {
      return state.currentRoom;
    },
    getRoomFound(state) {
      return state.roomFound;
    },
    getRoomAdmin(state) {
      return state.roomAdmin;
    },
    getPlayers(state) {
      return state.players;
    },
    getBrush(state) {
      return state.brush;
    },
    getBrushHistory(state) {
      return state.brushHistory;
    },
    getBrushUndo(state) {
      return state.brushUndo;
    },
    getUndoLimit(state) {
      return state.undoLimit;
    },
    getBrushOneChance(state) {
      return state.brushOneChance;
    },
    getPlayerActive(state) {
      const activePlayer = state.players.find(p => p.active === true);
      return activePlayer;
    },
    getSecretArtist(state) {
      const secretArtist = state.players.find(p => p.secretArtist === true);
      return secretArtist;
    },
    getSecretArtistFound(state) {
      return state.secretArtistFound;
    },
    getChatMessages(state) {
      return state.chatMessages;
    },
    getGameStage(state) {
      return state.gameStage;
    },
    isUsernameSelected(state) {
      return state.usernameSelected;
    },
    getUsername(state) {
      return state.username;
    },
    getVotingRoundGrace(state) {
      return state.votingRoundGrace;
    },
    getVotingRound(state) {
      return state.votingRound;
    },
    getVotingFinished(state) {
      return state.votingFinished;
    },
    getVotingTimer(state) {
      return state.votingTimer;
    },
    getVotes(state) {
      return state.votes;
    },
    getCurrentWord(state) {
      return state.currentWord;
    },
    getCustomWords(state) {
      return state.customWords;
    },
    getUseCustomWordsOnly(state) {
      return state.useCustomWordsOnly;
    },
    getWinState(state) {
      return state.win;
    },
    isNavOpen(state) {
      return state.isNavOpen;
    },
    getGlobalError(state) {
      return state.globalError;
    },
    getKicked(state) {
      return state.kicked;
    }
  }
});

export default store;
