const words = [
  {
    name: 'accolade',
    alternativeAnswers: ''
  },
  {
    name: 'accordion',
    alternativeAnswers: ''
  },
  {
    name: 'accountant',
    alternativeAnswers: ''
  },
  {
    name: 'ace',
    alternativeAnswers: ''
  },
  {
    name: 'acid',
    alternativeAnswers: ''
  },
  {
    name: 'acne',
    alternativeAnswers: ''
  },
  {
    name: 'acorn',
    alternativeAnswers: ''
  },
  {
    name: 'acre',
    alternativeAnswers: ''
  },
  {
    name: 'acrobat',
    alternativeAnswers: ''
  },
  {
    name: 'acropolis',
    alternativeAnswers: ''
  },
  {
    name: 'act',
    alternativeAnswers: ''
  },
  {
    name: 'action',
    alternativeAnswers: ''
  },
  {
    name: 'active',
    alternativeAnswers: ''
  },
  {
    name: 'activist',
    alternativeAnswers: ''
  },
  {
    name: 'actor',
    alternativeAnswers: ''
  },
  {
    name: 'actress',
    alternativeAnswers: ''
  },
  {
    name: 'actuator',
    alternativeAnswers: ''
  },
  {
    name: 'acupuncture',
    alternativeAnswers: ''
  },
  {
    name: 'adapter',
    alternativeAnswers: ''
  },
  {
    name: 'add',
    alternativeAnswers: ''
  },
  {
    name: 'adder',
    alternativeAnswers: ''
  },
  {
    name: 'admiral',
    alternativeAnswers: ''
  },
  {
    name: 'adore',
    alternativeAnswers: ''
  },
  {
    name: 'adrenaline',
    alternativeAnswers: ''
  },
  {
    name: 'adrift',
    alternativeAnswers: ''
  },
  {
    name: 'advert',
    alternativeAnswers: ''
  },
  {
    name: 'aerial',
    alternativeAnswers: ''
  },
  {
    name: 'aerobics',
    alternativeAnswers: ''
  },
  {
    name: 'aerosol',
    alternativeAnswers: ''
  },
  {
    name: 'affair',
    alternativeAnswers: ''
  },
  {
    name: 'aged',
    alternativeAnswers: ''
  },
  {
    name: 'airbag',
    alternativeAnswers: ''
  },
  {
    name: 'alarm',
    alternativeAnswers: ''
  },
  {
    name: 'alien',
    alternativeAnswers: ''
  },
  {
    name: 'alley',
    alternativeAnswers: ''
  },
  {
    name: 'allotment',
    alternativeAnswers: ''
  },
  {
    name: 'alphabet',
    alternativeAnswers: ''
  },
  {
    name: 'anchor',
    alternativeAnswers: ''
  },
  {
    name: 'ant',
    alternativeAnswers: ''
  },
  {
    name: 'antlers',
    alternativeAnswers: ''
  },
  {
    name: 'ape',
    alternativeAnswers: ''
  },
  {
    name: 'app',
    alternativeAnswers: 'apps'
  },
  {
    name: 'apple',
    alternativeAnswers: ''
  },
  {
    name: 'aquarium',
    alternativeAnswers: 'fishtank'
  },
  {
    name: 'armour',
    alternativeAnswers: 'armor'
  },
  {
    name: 'art',
    alternativeAnswers: 'arts, artwork'
  },
  {
    name: 'artery',
    alternativeAnswers: ''
  },
  {
    name: 'asparagus',
    alternativeAnswers: ''
  },
  {
    name: 'asterisk',
    alternativeAnswers: ''
  },
  {
    name: 'asteroid',
    alternativeAnswers: ''
  },
  {
    name: 'astronaut',
    alternativeAnswers: ''
  },
  {
    name: 'atom',
    alternativeAnswers: 'atoms'
  },
  {
    name: 'audience',
    alternativeAnswers: ''
  },
  {
    name: 'autobahn',
    alternativeAnswers: 'motorway, freeway'
  },
  {
    name: 'autumn',
    alternativeAnswers: 'fall'
  },
  {
    name: 'avalanche',
    alternativeAnswers: ''
  },
  {
    name: 'avatar',
    alternativeAnswers: ''
  },
  {
    name: 'aviators',
    alternativeAnswers: 'sunglasses'
  },
  {
    name: 'avocado',
    alternativeAnswers: ''
  },
  {
    name: 'award',
    alternativeAnswers: ''
  },
  {
    name: 'axe',
    alternativeAnswers: ''
  },
  {
    name: 'baboon',
    alternativeAnswers: ''
  },
  {
    name: 'baby',
    alternativeAnswers: ''
  },
  {
    name: 'backgammon',
    alternativeAnswers: ''
  },
  {
    name: 'backpack',
    alternativeAnswers: ''
  },
  {
    name: 'bacteria',
    alternativeAnswers: ''
  },
  {
    name: 'badge',
    alternativeAnswers: ''
  },
  {
    name: 'badger',
    alternativeAnswers: ''
  },
  {
    name: 'badminton',
    alternativeAnswers: ''
  },
  {
    name: 'bag',
    alternativeAnswers: ''
  },
  {
    name: 'bagel',
    alternativeAnswers: ''
  },
  {
    name: 'bagpipes',
    alternativeAnswers: 'bagpipe'
  },
  {
    name: 'baguette',
    alternativeAnswers: ''
  },
  {
    name: 'balaclava',
    alternativeAnswers: ''
  },
  {
    name: 'bald',
    alternativeAnswers: ''
  },
  {
    name: 'ball',
    alternativeAnswers: ''
  },
  {
    name: 'ballerina',
    alternativeAnswers: 'ballet dancer'
  },
  {
    name: 'balloon',
    alternativeAnswers: ''
  },
  {
    name: 'bandage',
    alternativeAnswers: ''
  },
  {
    name: 'banjo',
    alternativeAnswers: ''
  },
  {
    name: 'bank',
    alternativeAnswers: ''
  },
  {
    name: 'barbecue',
    alternativeAnswers: 'bbq'
  },
  {
    name: 'bark',
    alternativeAnswers: ''
  },
  {
    name: 'barn',
    alternativeAnswers: ''
  },
  {
    name: 'barrel',
    alternativeAnswers: ''
  },
  {
    name: 'baseball',
    alternativeAnswers: ''
  },
  {
    name: 'basket',
    alternativeAnswers: ''
  },
  {
    name: 'bass guitar',
    alternativeAnswers: ''
  },
  {
    name: 'bat',
    alternativeAnswers: ''
  },
  {
    name: 'bath',
    alternativeAnswers: ''
  },
  {
    name: 'battery',
    alternativeAnswers: 'cell'
  },
  {
    name: 'beachball',
    alternativeAnswers: ''
  },
  {
    name: 'bear',
    alternativeAnswers: ''
  },
  {
    name: 'beard',
    alternativeAnswers: ''
  },
  {
    name: 'bed',
    alternativeAnswers: ''
  },
  {
    name: 'bee',
    alternativeAnswers: 'bumblebee'
  },
  {
    name: 'beer',
    alternativeAnswers: ''
  },
  {
    name: 'bell',
    alternativeAnswers: ''
  },
  {
    name: 'bib',
    alternativeAnswers: ''
  },
  {
    name: 'bible',
    alternativeAnswers: ''
  },
  {
    name: 'bicycle',
    alternativeAnswers: 'bike'
  },
  {
    name: 'bigfoot',
    alternativeAnswers: 'sasquatch'
  },
  {
    name: 'bike',
    alternativeAnswers: ''
  },
  {
    name: 'bikini',
    alternativeAnswers: ''
  },
  {
    name: 'bin',
    alternativeAnswers: ''
  },
  {
    name: 'binoculars',
    alternativeAnswers: ''
  },
  {
    name: 'biplane',
    alternativeAnswers: ''
  },
  {
    name: 'bird',
    alternativeAnswers: ''
  },
  {
    name: 'birth',
    alternativeAnswers: ''
  },
  {
    name: 'biscuit',
    alternativeAnswers: ''
  },
  {
    name: 'bishop',
    alternativeAnswers: ''
  },
  {
    name: 'bitcoin',
    alternativeAnswers: ''
  },
  {
    name: 'bite',
    alternativeAnswers: ''
  },
  {
    name: 'blackjack',
    alternativeAnswers: ''
  },
  {
    name: 'blimp',
    alternativeAnswers: 'airship'
  },
  {
    name: 'blockbuster',
    alternativeAnswers: ''
  },
  {
    name: 'blonde',
    alternativeAnswers: ''
  },
  {
    name: 'blood',
    alternativeAnswers: ''
  },
  {
    name: 'boar',
    alternativeAnswers: ''
  },
  {
    name: 'boat',
    alternativeAnswers: 'ship'
  },
  {
    name: 'bobsled',
    alternativeAnswers: ''
  },
  {
    name: 'bodybuilder',
    alternativeAnswers: 'weightlifter'
  },
  {
    name: 'bogey',
    alternativeAnswers: ''
  },
  {
    name: 'boil',
    alternativeAnswers: ''
  },
  {
    name: 'bolt',
    alternativeAnswers: ''
  },
  {
    name: 'bomb',
    alternativeAnswers: ''
  },
  {
    name: 'bonfire',
    alternativeAnswers: ''
  },
  {
    name: 'book',
    alternativeAnswers: ''
  },
  {
    name: 'bookmark',
    alternativeAnswers: ''
  },
  {
    name: 'boomerang',
    alternativeAnswers: ''
  },
  {
    name: 'boot',
    alternativeAnswers: ''
  },
  {
    name: 'booty',
    alternativeAnswers: ''
  },
  {
    name: 'bottle',
    alternativeAnswers: ''
  },
  {
    name: 'bounce',
    alternativeAnswers: ''
  },
  {
    name: 'bowel',
    alternativeAnswers: 'bowels'
  },
  {
    name: 'bowl',
    alternativeAnswers: ''
  },
  {
    name: 'bowler hat',
    alternativeAnswers: ''
  },
  {
    name: 'bowtie',
    alternativeAnswers: ''
  },
  {
    name: 'box',
    alternativeAnswers: ''
  },
  {
    name: 'boxing',
    alternativeAnswers: ''
  },
  {
    name: 'bra',
    alternativeAnswers: ''
  },
  {
    name: 'brain',
    alternativeAnswers: ''
  },
  {
    name: 'bread',
    alternativeAnswers: ''
  },
  {
    name: 'breakfast',
    alternativeAnswers: ''
  },
  {
    name: 'brick',
    alternativeAnswers: 'bricks, brickwork'
  },
  {
    name: 'bride',
    alternativeAnswers: ''
  },
  {
    name: 'broken',
    alternativeAnswers: ''
  },
  {
    name: 'brooch',
    alternativeAnswers: 'broach'
  },
  {
    name: 'broom',
    alternativeAnswers: 'brush'
  },
  {
    name: 'broomstick',
    alternativeAnswers: ''
  },
  {
    name: 'brownie',
    alternativeAnswers: 'brownies'
  },
  {
    name: 'brush',
    alternativeAnswers: ''
  },
  {
    name: 'bubble',
    alternativeAnswers: 'bubbles'
  },
  {
    name: 'bubblegum',
    alternativeAnswers: 'bubble gum, chewing gum'
  },
  {
    name: 'buddha',
    alternativeAnswers: ''
  },
  {
    name: 'budgie',
    alternativeAnswers: 'budgerigar'
  },
  {
    name: 'bug',
    alternativeAnswers: ''
  },
  {
    name: 'bull',
    alternativeAnswers: ''
  },
  {
    name: 'bulldog',
    alternativeAnswers: ''
  },
  {
    name: 'bum',
    alternativeAnswers: 'butt, arse, rear-end'
  },
  {
    name: 'bunny',
    alternativeAnswers: 'rabbit'
  },
  {
    name: 'burrito',
    alternativeAnswers: ''
  },
  {
    name: 'bus',
    alternativeAnswers: ''
  },
  {
    name: 'bush',
    alternativeAnswers: ''
  },
  {
    name: 'butter',
    alternativeAnswers: ''
  },
  {
    name: 'button',
    alternativeAnswers: ''
  },
  {
    name: 'cab',
    alternativeAnswers: 'taxi'
  },
  {
    name: 'cable',
    alternativeAnswers: ''
  },
  {
    name: 'cactus',
    alternativeAnswers: ''
  },
  {
    name: 'cage',
    alternativeAnswers: ''
  },
  {
    name: 'cake',
    alternativeAnswers: ''
  },
  {
    name: 'camel',
    alternativeAnswers: ''
  },
  {
    name: 'camera',
    alternativeAnswers: ''
  },
  {
    name: 'camping',
    alternativeAnswers: ''
  },
  {
    name: 'can',
    alternativeAnswers: ''
  },
  {
    name: 'can opener',
    alternativeAnswers: ''
  },
  {
    name: 'canary',
    alternativeAnswers: ''
  },
  {
    name: 'candle',
    alternativeAnswers: ''
  },
  {
    name: 'cannon',
    alternativeAnswers: ''
  },
  {
    name: 'cappuccino',
    alternativeAnswers: 'coffee'
  },
  {
    name: 'car',
    alternativeAnswers: 'automobile, vehicle'
  },
  {
    name: 'card',
    alternativeAnswers: ''
  },
  {
    name: 'cash',
    alternativeAnswers: ''
  },
  {
    name: 'casino',
    alternativeAnswers: ''
  },
  {
    name: 'castle',
    alternativeAnswers: ''
  },
  {
    name: 'cat',
    alternativeAnswers: ''
  },
  {
    name: 'caterpillar',
    alternativeAnswers: ''
  },
  {
    name: 'cave',
    alternativeAnswers: ''
  },
  {
    name: 'cello',
    alternativeAnswers: ''
  },
  {
    name: 'centipede',
    alternativeAnswers: ''
  },
  {
    name: 'chainsaw',
    alternativeAnswers: ''
  },
  {
    name: 'champagne',
    alternativeAnswers: 'sparkling wine'
  },
  {
    name: 'charger',
    alternativeAnswers: ''
  },
  {
    name: 'charlie chaplin',
    alternativeAnswers: 'chaplin'
  },
  {
    name: 'cheerleader',
    alternativeAnswers: ''
  },
  {
    name: 'cheese',
    alternativeAnswers: ''
  },
  {
    name: 'cheetah',
    alternativeAnswers: ''
  },
  {
    name: 'chef',
    alternativeAnswers: ''
  },
  {
    name: 'cheque',
    alternativeAnswers: ''
  },
  {
    name: 'cherry',
    alternativeAnswers: 'cherries'
  },
  {
    name: 'chess',
    alternativeAnswers: 'chess board, chessboard'
  },
  {
    name: 'chicken',
    alternativeAnswers: ''
  },
  {
    name: 'chimney',
    alternativeAnswers: ''
  },
  {
    name: 'chopsticks',
    alternativeAnswers: 'chopstick'
  },
  {
    name: 'chord',
    alternativeAnswers: ''
  },
  {
    name: 'christmas',
    alternativeAnswers: 'xmas'
  },
  {
    name: 'church',
    alternativeAnswers: ''
  },
  {
    name: 'cigarette',
    alternativeAnswers: ''
  },
  {
    name: 'cinema',
    alternativeAnswers: ''
  },
  {
    name: 'circle',
    alternativeAnswers: ''
  },
  {
    name: 'circus',
    alternativeAnswers: ''
  },
  {
    name: 'city',
    alternativeAnswers: ''
  },
  {
    name: 'clap',
    alternativeAnswers: ''
  },
  {
    name: 'claw',
    alternativeAnswers: ''
  },
  {
    name: 'cliff',
    alternativeAnswers: ''
  },
  {
    name: 'clock',
    alternativeAnswers: ''
  },
  {
    name: 'cloud',
    alternativeAnswers: ''
  },
  {
    name: 'clown',
    alternativeAnswers: ''
  },
  {
    name: 'cobra',
    alternativeAnswers: ''
  },
  {
    name: 'cockroach',
    alternativeAnswers: ''
  },
  {
    name: 'cocktail',
    alternativeAnswers: ''
  },
  {
    name: 'coconut',
    alternativeAnswers: ''
  },
  {
    name: 'coffin',
    alternativeAnswers: ''
  },
  {
    name: 'comb',
    alternativeAnswers: ''
  },
  {
    name: 'comet',
    alternativeAnswers: ''
  },
  {
    name: 'comic book',
    alternativeAnswers: ''
  },
  {
    name: 'communism',
    alternativeAnswers: ''
  },
  {
    name: 'computer',
    alternativeAnswers: 'pc'
  },
  {
    name: 'cook',
    alternativeAnswers: ''
  },
  {
    name: 'cookie',
    alternativeAnswers: ''
  },
  {
    name: 'coral',
    alternativeAnswers: 'coral reef'
  },
  {
    name: 'cork',
    alternativeAnswers: ''
  },
  {
    name: 'corkscrew',
    alternativeAnswers: ''
  },
  {
    name: 'corn',
    alternativeAnswers: ''
  },
  {
    name: 'cotton',
    alternativeAnswers: ''
  },
  {
    name: 'cough',
    alternativeAnswers: ''
  },
  {
    name: 'cow',
    alternativeAnswers: ''
  },
  {
    name: 'crab',
    alternativeAnswers: ''
  },
  {
    name: 'crayon',
    alternativeAnswers: ''
  },
  {
    name: 'credit card',
    alternativeAnswers: 'debit card'
  },
  {
    name: 'cricket',
    alternativeAnswers: ''
  },
  {
    name: 'crocodile',
    alternativeAnswers: ''
  },
  {
    name: 'croissant',
    alternativeAnswers: ''
  },
  {
    name: 'crossbow',
    alternativeAnswers: ''
  },
  {
    name: 'crow',
    alternativeAnswers: ''
  },
  {
    name: 'crown',
    alternativeAnswers: ''
  },
  {
    name: 'crystal',
    alternativeAnswers: ''
  },
  {
    name: 'cucumber',
    alternativeAnswers: ''
  },
  {
    name: 'cupcake',
    alternativeAnswers: ''
  },
  {
    name: 'cupid',
    alternativeAnswers: ''
  },
  {
    name: 'curtain',
    alternativeAnswers: 'curtains'
  },
  {
    name: 'cushion',
    alternativeAnswers: ''
  },
  {
    name: 'cut',
    alternativeAnswers: ''
  },
  {
    name: 'cylinder',
    alternativeAnswers: ''
  },
  {
    name: 'cymbal',
    alternativeAnswers: ''
  },
  {
    name: 'dandruff',
    alternativeAnswers: ''
  },
  {
    name: 'darts',
    alternativeAnswers: 'dartboard'
  },
  {
    name: 'darwin',
    alternativeAnswers: ''
  },
  {
    name: 'date',
    alternativeAnswers: ''
  },
  {
    name: 'death',
    alternativeAnswers: ''
  },
  {
    name: 'debt',
    alternativeAnswers: ''
  },
  {
    name: 'deer',
    alternativeAnswers: ''
  },
  {
    name: 'degree',
    alternativeAnswers: ''
  },
  {
    name: 'delete',
    alternativeAnswers: ''
  },
  {
    name: 'delivery',
    alternativeAnswers: ''
  },
  {
    name: 'democracy',
    alternativeAnswers: ''
  },
  {
    name: 'demolish',
    alternativeAnswers: ''
  },
  {
    name: 'demon',
    alternativeAnswers: ''
  },
  {
    name: 'dentist',
    alternativeAnswers: ''
  },
  {
    name: 'deodorant',
    alternativeAnswers: ''
  },
  {
    name: 'deposit',
    alternativeAnswers: ''
  },
  {
    name: 'desert',
    alternativeAnswers: ''
  },
  {
    name: 'desk',
    alternativeAnswers: ''
  },
  {
    name: 'dessert',
    alternativeAnswers: ''
  },
  {
    name: 'detonate',
    alternativeAnswers: ''
  },
  {
    name: 'dew',
    alternativeAnswers: ''
  },
  {
    name: 'diagonal',
    alternativeAnswers: ''
  },
  {
    name: 'diamond',
    alternativeAnswers: ''
  },
  {
    name: 'dice',
    alternativeAnswers: ''
  },
  {
    name: 'dictionary',
    alternativeAnswers: ''
  },
  {
    name: 'die',
    alternativeAnswers: ''
  },
  {
    name: 'diet',
    alternativeAnswers: ''
  },
  {
    name: 'dig',
    alternativeAnswers: ''
  },
  {
    name: 'digital',
    alternativeAnswers: ''
  },
  {
    name: 'dimension',
    alternativeAnswers: ''
  },
  {
    name: 'dinner',
    alternativeAnswers: ''
  },
  {
    name: 'dinosaur',
    alternativeAnswers: ''
  },
  {
    name: 'director',
    alternativeAnswers: ''
  },
  {
    name: 'dirty',
    alternativeAnswers: ''
  },
  {
    name: 'disaster',
    alternativeAnswers: ''
  },
  {
    name: 'disco',
    alternativeAnswers: ''
  },
  {
    name: 'discount',
    alternativeAnswers: ''
  },
  {
    name: 'dish',
    alternativeAnswers: ''
  },
  {
    name: 'disk',
    alternativeAnswers: 'disc'
  },
  {
    name: 'diva',
    alternativeAnswers: ''
  },
  {
    name: 'dive',
    alternativeAnswers: ''
  },
  {
    name: 'divorce',
    alternativeAnswers: ''
  },
  {
    name: 'dna',
    alternativeAnswers: ''
  },
  {
    name: 'doctor',
    alternativeAnswers: ''
  },
  {
    name: 'dog',
    alternativeAnswers: ''
  },
  {
    name: 'doll',
    alternativeAnswers: ''
  },
  {
    name: 'dollar',
    alternativeAnswers: ''
  },
  {
    name: 'dolphin',
    alternativeAnswers: ''
  },
  {
    name: 'dominoes',
    alternativeAnswers: 'domino'
  },
  {
    name: 'donkey',
    alternativeAnswers: ''
  },
  {
    name: 'dough',
    alternativeAnswers: ''
  },
  {
    name: 'download',
    alternativeAnswers: ''
  },
  {
    name: 'dozen',
    alternativeAnswers: ''
  },
  {
    name: 'dracula',
    alternativeAnswers: ''
  },
  {
    name: 'dragon',
    alternativeAnswers: ''
  },
  {
    name: 'dragonfly',
    alternativeAnswers: ''
  },
  {
    name: 'draw',
    alternativeAnswers: ''
  },
  {
    name: 'drawer',
    alternativeAnswers: ''
  },
  {
    name: 'dream',
    alternativeAnswers: ''
  },
  {
    name: 'dress',
    alternativeAnswers: ''
  },
  {
    name: 'dressing',
    alternativeAnswers: ''
  },
  {
    name: 'drill',
    alternativeAnswers: ''
  },
  {
    name: 'drink',
    alternativeAnswers: ''
  },
  {
    name: 'drip',
    alternativeAnswers: ''
  },
  {
    name: 'drive',
    alternativeAnswers: ''
  },
  {
    name: 'drop',
    alternativeAnswers: ''
  },
  {
    name: 'drum',
    alternativeAnswers: ''
  },
  {
    name: 'dust',
    alternativeAnswers: ''
  },
  {
    name: 'dynamite',
    alternativeAnswers: ''
  },
  {
    name: 'eagle',
    alternativeAnswers: ''
  },
  {
    name: 'ear',
    alternativeAnswers: ''
  },
  {
    name: 'earbuds',
    alternativeAnswers: ''
  },
  {
    name: 'earth',
    alternativeAnswers: ''
  },
  {
    name: 'earthquake',
    alternativeAnswers: ''
  },
  {
    name: 'easter',
    alternativeAnswers: ''
  },
  {
    name: 'easy',
    alternativeAnswers: ''
  },
  {
    name: 'eclipse',
    alternativeAnswers: ''
  },
  {
    name: 'education',
    alternativeAnswers: ''
  },
  {
    name: 'eel',
    alternativeAnswers: ''
  },
  {
    name: 'egg',
    alternativeAnswers: ''
  },
  {
    name: 'egypt',
    alternativeAnswers: ''
  },
  {
    name: 'eiffel tower',
    alternativeAnswers: ''
  },
  {
    name: 'einstein',
    alternativeAnswers: ''
  },
  {
    name: 'election',
    alternativeAnswers: ''
  },
  {
    name: 'electric guitar',
    alternativeAnswers: 'guitar'
  },
  {
    name: 'electricity',
    alternativeAnswers: ''
  },
  {
    name: 'electricity',
    alternativeAnswers: ''
  },
  {
    name: 'electron',
    alternativeAnswers: ''
  },
  {
    name: 'elephant',
    alternativeAnswers: ''
  },
  {
    name: 'embryo',
    alternativeAnswers: ''
  },
  {
    name: 'emoji',
    alternativeAnswers: ''
  },
  {
    name: 'emu',
    alternativeAnswers: ''
  },
  {
    name: 'engine',
    alternativeAnswers: ''
  },
  {
    name: 'england',
    alternativeAnswers: ''
  },
  {
    name: 'envelope',
    alternativeAnswers: ''
  },
  {
    name: 'equation',
    alternativeAnswers: ''
  },
  {
    name: 'equator',
    alternativeAnswers: ''
  },
  {
    name: 'espresso',
    alternativeAnswers: ''
  },
  {
    name: 'europe',
    alternativeAnswers: ''
  },
  {
    name: 'exercise',
    alternativeAnswers: ''
  },
  {
    name: 'explosion',
    alternativeAnswers: ''
  },
  {
    name: 'extraterrestrial',
    alternativeAnswers: 'ET, alien'
  },
  {
    name: 'eye',
    alternativeAnswers: ''
  },
  {
    name: 'eyebrow',
    alternativeAnswers: ''
  },
  {
    name: 'eyelash',
    alternativeAnswers: ''
  },
  {
    name: 'face',
    alternativeAnswers: ''
  },
  {
    name: 'fairy',
    alternativeAnswers: ''
  },
  {
    name: 'fame',
    alternativeAnswers: ''
  },
  {
    name: 'family',
    alternativeAnswers: ''
  },
  {
    name: 'farmer',
    alternativeAnswers: ''
  },
  {
    name: 'feather',
    alternativeAnswers: ''
  },
  {
    name: 'fern',
    alternativeAnswers: ''
  },
  {
    name: 'ferris wheel',
    alternativeAnswers: ''
  },
  {
    name: 'festival',
    alternativeAnswers: ''
  },
  {
    name: 'fight',
    alternativeAnswers: ''
  },
  {
    name: 'finger',
    alternativeAnswers: ''
  },
  {
    name: 'fire',
    alternativeAnswers: ''
  },
  {
    name: 'fireplace',
    alternativeAnswers: ''
  },
  {
    name: 'firework',
    alternativeAnswers: ''
  },
  {
    name: 'fish',
    alternativeAnswers: ''
  },
  {
    name: 'fit',
    alternativeAnswers: ''
  },
  {
    name: 'flag',
    alternativeAnswers: ''
  },
  {
    name: 'flamingo',
    alternativeAnswers: ''
  },
  {
    name: 'flea',
    alternativeAnswers: ''
  },
  {
    name: 'flower',
    alternativeAnswers: ''
  },
  {
    name: 'flu',
    alternativeAnswers: ''
  },
  {
    name: 'flute',
    alternativeAnswers: ''
  },
  {
    name: 'fly',
    alternativeAnswers: ''
  },
  {
    name: 'folder',
    alternativeAnswers: ''
  },
  {
    name: 'football',
    alternativeAnswers: ''
  },
  {
    name: 'forest',
    alternativeAnswers: 'wood, woodland'
  },
  {
    name: 'fork',
    alternativeAnswers: ''
  },
  {
    name: 'fossil',
    alternativeAnswers: ''
  },
  {
    name: 'fox',
    alternativeAnswers: ''
  },
  {
    name: 'france',
    alternativeAnswers: ''
  },
  {
    name: 'freckles',
    alternativeAnswers: 'freckle'
  },
  {
    name: 'fries',
    alternativeAnswers: 'chips'
  },
  {
    name: 'frog',
    alternativeAnswers: ''
  },
  {
    name: 'frozen',
    alternativeAnswers: ''
  },
  {
    name: 'fruit',
    alternativeAnswers: ''
  },
  {
    name: 'galaxy',
    alternativeAnswers: ''
  },
  {
    name: 'gandalf',
    alternativeAnswers: ''
  },
  {
    name: 'gas mask',
    alternativeAnswers: ''
  },
  {
    name: 'gear',
    alternativeAnswers: ''
  },
  {
    name: 'genie',
    alternativeAnswers: ''
  },
  {
    name: 'giant',
    alternativeAnswers: ''
  },
  {
    name: 'giraffe',
    alternativeAnswers: ''
  },
  {
    name: 'glove',
    alternativeAnswers: ''
  },
  {
    name: 'gnome',
    alternativeAnswers: ''
  },
  {
    name: 'goat',
    alternativeAnswers: ''
  },
  {
    name: 'golf',
    alternativeAnswers: ''
  },
  {
    name: 'gorilla',
    alternativeAnswers: ''
  },
  {
    name: 'grapes',
    alternativeAnswers: ''
  },
  {
    name: 'grasshopper',
    alternativeAnswers: ''
  },
  {
    name: 'gravity',
    alternativeAnswers: ''
  },
  {
    name: 'guillotine',
    alternativeAnswers: ''
  },
  {
    name: 'guinea pig',
    alternativeAnswers: ''
  },
  {
    name: 'guitar',
    alternativeAnswers: ''
  },
  {
    name: 'gun',
    alternativeAnswers: ''
  },
  {
    name: 'hairbrush',
    alternativeAnswers: ''
  },
  {
    name: 'hairy',
    alternativeAnswers: 'hair'
  },
  {
    name: 'halo',
    alternativeAnswers: ''
  },
  {
    name: 'hamburger',
    alternativeAnswers: 'burger'
  },
  {
    name: 'hammer',
    alternativeAnswers: ''
  },
  {
    name: 'hammock',
    alternativeAnswers: ''
  },
  {
    name: 'hamster',
    alternativeAnswers: ''
  },
  {
    name: 'hand',
    alternativeAnswers: ''
  },
  {
    name: 'harmonica',
    alternativeAnswers: ''
  },
  {
    name: 'harp',
    alternativeAnswers: ''
  },
  {
    name: 'hashtag',
    alternativeAnswers: ''
  },
  {
    name: 'hat',
    alternativeAnswers: ''
  },
  {
    name: 'headphones',
    alternativeAnswers: 'earphones'
  },
  {
    name: 'heart',
    alternativeAnswers: ''
  },
  {
    name: 'hedgehog',
    alternativeAnswers: ''
  },
  {
    name: 'helicopter',
    alternativeAnswers: ''
  },
  {
    name: 'helmet',
    alternativeAnswers: ''
  },
  {
    name: 'hexagon',
    alternativeAnswers: ''
  },
  {
    name: 'hieroglyph',
    alternativeAnswers: 'hieroglyphs, hieroglyphic, hieroglyphics'
  },
  {
    name: 'high heels',
    alternativeAnswers: 'highheels'
  },
  {
    name: 'hill',
    alternativeAnswers: ''
  },
  {
    name: 'hip hop',
    alternativeAnswers: 'hip-hop'
  },
  {
    name: 'hippo',
    alternativeAnswers: 'hippopotamus'
  },
  {
    name: 'hitchhiker',
    alternativeAnswers: ''
  },
  {
    name: 'hobbit',
    alternativeAnswers: 'the hobbit'
  },
  {
    name: 'hockey',
    alternativeAnswers: ''
  },
  {
    name: 'holiday',
    alternativeAnswers: ''
  },
  {
    name: 'hollywood',
    alternativeAnswers: ''
  },
  {
    name: 'home alone',
    alternativeAnswers: ''
  },
  {
    name: 'homer simpson',
    alternativeAnswers: 'homer'
  },
  {
    name: 'honey',
    alternativeAnswers: ''
  },
  {
    name: 'horoscope',
    alternativeAnswers: ''
  },
  {
    name: 'hospital',
    alternativeAnswers: ''
  },
  {
    name: 'hot dog',
    alternativeAnswers: 'hotdog'
  },
  {
    name: 'house',
    alternativeAnswers: ''
  },
  {
    name: 'houseplant',
    alternativeAnswers: 'plant'
  },
  {
    name: 'hula hoop',
    alternativeAnswers: 'hulahoop'
  },
  {
    name: 'hummingbird',
    alternativeAnswers: ''
  },
  {
    name: 'hypnotise',
    alternativeAnswers: 'hypnotised, hypnotize, hypnotized'
  },
  {
    name: 'ice cream',
    alternativeAnswers: 'icecream'
  },
  {
    name: 'iceberg',
    alternativeAnswers: ''
  },
  {
    name: 'icicle',
    alternativeAnswers: ''
  },
  {
    name: 'illusion',
    alternativeAnswers: ''
  },
  {
    name: 'incognito',
    alternativeAnswers: ''
  },
  {
    name: 'india',
    alternativeAnswers: ''
  },
  {
    name: 'infinite',
    alternativeAnswers: 'infinity'
  },
  {
    name: 'inject',
    alternativeAnswers: 'injection'
  },
  {
    name: 'insect',
    alternativeAnswers: ''
  },
  {
    name: 'insomnia',
    alternativeAnswers: ''
  },
  {
    name: 'internet',
    alternativeAnswers: ''
  },
  {
    name: 'ipad',
    alternativeAnswers: 'tablet'
  },
  {
    name: 'ireland',
    alternativeAnswers: ''
  },
  {
    name: 'iron man',
    alternativeAnswers: 'ironman'
  },
  {
    name: 'island',
    alternativeAnswers: ''
  },
  {
    name: 'italy',
    alternativeAnswers: ''
  },
  {
    name: 'ivory',
    alternativeAnswers: ''
  },
  {
    name: 'ivy',
    alternativeAnswers: ''
  },
  {
    name: 'jaguar',
    alternativeAnswers: ''
  },
  {
    name: 'jail',
    alternativeAnswers: ''
  },
  {
    name: 'james bond',
    alternativeAnswers: '007, bond'
  },
  {
    name: 'japan',
    alternativeAnswers: ''
  },
  {
    name: 'jaw',
    alternativeAnswers: ''
  },
  {
    name: 'jazz',
    alternativeAnswers: ''
  },
  {
    name: 'jelly',
    alternativeAnswers: ''
  },
  {
    name: 'jellyfish',
    alternativeAnswers: ''
  },
  {
    name: 'jester',
    alternativeAnswers: ''
  },
  {
    name: 'jet ski',
    alternativeAnswers: 'jetski'
  },
  {
    name: 'jockey',
    alternativeAnswers: ''
  },
  {
    name: 'joint',
    alternativeAnswers: ''
  },
  {
    name: 'judge',
    alternativeAnswers: ''
  },
  {
    name: 'juggle',
    alternativeAnswers: ''
  },
  {
    name: 'kangaroo',
    alternativeAnswers: ''
  },
  {
    name: 'karaoke',
    alternativeAnswers: ''
  },
  {
    name: 'karate',
    alternativeAnswers: ''
  },
  {
    name: 'kebab',
    alternativeAnswers: ''
  },
  {
    name: 'kermit',
    alternativeAnswers: 'kermit the frog'
  },
  {
    name: 'ketchup',
    alternativeAnswers: ''
  },
  {
    name: 'kettle',
    alternativeAnswers: ''
  },
  {
    name: 'keyboard',
    alternativeAnswers: ''
  },
  {
    name: 'kidney',
    alternativeAnswers: ''
  },
  {
    name: 'kiss',
    alternativeAnswers: ''
  },
  {
    name: 'kite',
    alternativeAnswers: ''
  },
  {
    name: 'kiwi',
    alternativeAnswers: ''
  },
  {
    name: 'knee',
    alternativeAnswers: ''
  },
  {
    name: 'kneel',
    alternativeAnswers: ''
  },
  {
    name: 'knife',
    alternativeAnswers: ''
  },
  {
    name: 'knight',
    alternativeAnswers: ''
  },
  {
    name: 'knit',
    alternativeAnswers: ''
  },
  {
    name: 'knot',
    alternativeAnswers: ''
  },
  {
    name: 'koala',
    alternativeAnswers: ''
  },
  {
    name: 'kraken',
    alternativeAnswers: ''
  },
  {
    name: 'lace',
    alternativeAnswers: ''
  },
  {
    name: 'ladder',
    alternativeAnswers: ''
  },
  {
    name: 'ladybird',
    alternativeAnswers: ''
  },
  {
    name: 'lake',
    alternativeAnswers: ''
  },
  {
    name: 'lamb',
    alternativeAnswers: ''
  },
  {
    name: 'lamp',
    alternativeAnswers: ''
  },
  {
    name: 'lantern',
    alternativeAnswers: ''
  },
  {
    name: 'laptop',
    alternativeAnswers: ''
  },
  {
    name: 'lasagna',
    alternativeAnswers: ''
  },
  {
    name: 'laser',
    alternativeAnswers: ''
  },
  {
    name: 'lasso',
    alternativeAnswers: ''
  },
  {
    name: 'lava',
    alternativeAnswers: ''
  },
  {
    name: 'lava lamp',
    alternativeAnswers: 'lavalamp'
  },
  {
    name: 'lawn mower',
    alternativeAnswers: 'lawnmower'
  },
  {
    name: 'leaf',
    alternativeAnswers: 'leaves'
  },
  {
    name: 'leech',
    alternativeAnswers: ''
  },
  {
    name: 'leg',
    alternativeAnswers: ''
  },
  {
    name: 'lego',
    alternativeAnswers: ''
  },
  {
    name: 'lemon',
    alternativeAnswers: ''
  },
  {
    name: 'lemur',
    alternativeAnswers: ''
  },
  {
    name: 'letter',
    alternativeAnswers: ''
  },
  {
    name: 'lettuce',
    alternativeAnswers: ''
  },
  {
    name: 'library',
    alternativeAnswers: ''
  },
  {
    name: 'lick',
    alternativeAnswers: ''
  },
  {
    name: 'lightbulb',
    alternativeAnswers: ''
  },
  {
    name: 'lighter',
    alternativeAnswers: ''
  },
  {
    name: 'lighthouse',
    alternativeAnswers: ''
  },
  {
    name: 'lightning',
    alternativeAnswers: ''
  },
  {
    name: 'lightsaber',
    alternativeAnswers: ''
  },
  {
    name: 'lilypad',
    alternativeAnswers: ''
  },
  {
    name: 'lime',
    alternativeAnswers: 'limes'
  },
  {
    name: 'limousine',
    alternativeAnswers: 'limo'
  },
  {
    name: 'lion',
    alternativeAnswers: ''
  },
  {
    name: 'lion king',
    alternativeAnswers: ''
  },
  {
    name: 'lips',
    alternativeAnswers: ''
  },
  {
    name: 'lipstick',
    alternativeAnswers: ''
  },
  {
    name: 'liver',
    alternativeAnswers: ''
  },
  {
    name: 'lizard',
    alternativeAnswers: ''
  },
  {
    name: 'llama',
    alternativeAnswers: ''
  },
  {
    name: 'loading',
    alternativeAnswers: ''
  },
  {
    name: 'lobster',
    alternativeAnswers: ''
  },
  {
    name: 'lock',
    alternativeAnswers: ''
  },
  {
    name: 'log',
    alternativeAnswers: ''
  },
  {
    name: 'logo',
    alternativeAnswers: ''
  },
  {
    name: 'lollipop',
    alternativeAnswers: 'lolly'
  },
  {
    name: 'london',
    alternativeAnswers: ''
  },
  {
    name: 'lottery',
    alternativeAnswers: 'lottery ticket'
  },
  {
    name: 'love',
    alternativeAnswers: ''
  },
  {
    name: 'luck',
    alternativeAnswers: ''
  },
  {
    name: 'luggage',
    alternativeAnswers: ''
  },
  {
    name: 'luigi',
    alternativeAnswers: ''
  },
  {
    name: 'lumberjack',
    alternativeAnswers: ''
  },
  {
    name: 'lung',
    alternativeAnswers: ''
  },
  {
    name: 'mafia',
    alternativeAnswers: ''
  },
  {
    name: 'magician',
    alternativeAnswers: ''
  },
  {
    name: 'magma',
    alternativeAnswers: ''
  },
  {
    name: 'magnet',
    alternativeAnswers: ''
  },
  {
    name: 'magnifier',
    alternativeAnswers: 'magnifying glass'
  },
  {
    name: 'maid',
    alternativeAnswers: ''
  },
  {
    name: 'makeup',
    alternativeAnswers: ''
  },
  {
    name: 'mammoth',
    alternativeAnswers: 'woolly mammoth'
  },
  {
    name: 'manager',
    alternativeAnswers: ''
  },
  {
    name: 'manatee',
    alternativeAnswers: 'sea cow, seacow'
  },
  {
    name: 'manhole',
    alternativeAnswers: ''
  },
  {
    name: 'manicure',
    alternativeAnswers: ''
  },
  {
    name: 'mansion',
    alternativeAnswers: ''
  },
  {
    name: 'manual',
    alternativeAnswers: ''
  },
  {
    name: 'map',
    alternativeAnswers: ''
  },
  {
    name: 'maracas',
    alternativeAnswers: ''
  },
  {
    name: 'marathon',
    alternativeAnswers: ''
  },
  {
    name: 'marble',
    alternativeAnswers: ''
  },
  {
    name: 'mario',
    alternativeAnswers: ''
  },
  {
    name: 'marmalade',
    alternativeAnswers: ''
  },
  {
    name: 'marriage',
    alternativeAnswers: ''
  },
  {
    name: 'mars',
    alternativeAnswers: ''
  },
  {
    name: 'marshmallow',
    alternativeAnswers: 'marshmallows'
  },
  {
    name: 'mask',
    alternativeAnswers: ''
  },
  {
    name: 'mass',
    alternativeAnswers: ''
  },
  {
    name: 'massage',
    alternativeAnswers: ''
  },
  {
    name: 'match',
    alternativeAnswers: 'matches, matchbox'
  },
  {
    name: 'mayonnaise',
    alternativeAnswers: 'mayo'
  },
  {
    name: 'mayor',
    alternativeAnswers: ''
  },
  {
    name: 'maze',
    alternativeAnswers: ''
  },
  {
    name: 'meatball',
    alternativeAnswers: 'meatballs'
  },
  {
    name: 'medal',
    alternativeAnswers: ''
  },
  {
    name: 'medieval',
    alternativeAnswers: ''
  },
  {
    name: 'medusa',
    alternativeAnswers: ''
  },
  {
    name: 'meerkat',
    alternativeAnswers: ''
  },
  {
    name: 'meeting',
    alternativeAnswers: ''
  },
  {
    name: 'megaphone',
    alternativeAnswers: ''
  },
  {
    name: 'melon',
    alternativeAnswers: ''
  },
  {
    name: 'member',
    alternativeAnswers: ''
  },
  {
    name: 'meme',
    alternativeAnswers: ''
  },
  {
    name: 'memory',
    alternativeAnswers: ''
  },
  {
    name: 'menu',
    alternativeAnswers: ''
  },
  {
    name: 'mercury',
    alternativeAnswers: ''
  },
  {
    name: 'mermaid',
    alternativeAnswers: ''
  },
  {
    name: 'message',
    alternativeAnswers: ''
  },
  {
    name: 'meteorite',
    alternativeAnswers: ''
  },
  {
    name: 'mexico',
    alternativeAnswers: ''
  },
  {
    name: 'michael jackson',
    alternativeAnswers: ''
  },
  {
    name: 'mickey mouse',
    alternativeAnswers: 'mickey'
  },
  {
    name: 'microphone',
    alternativeAnswers: ''
  },
  {
    name: 'microscope',
    alternativeAnswers: ''
  },
  {
    name: 'microwave',
    alternativeAnswers: ''
  },
  {
    name: 'midnight',
    alternativeAnswers: ''
  },
  {
    name: 'milk',
    alternativeAnswers: ''
  },
  {
    name: 'milkman',
    alternativeAnswers: ''
  },
  {
    name: 'milkshake',
    alternativeAnswers: ''
  },
  {
    name: 'mime',
    alternativeAnswers: ''
  },
  {
    name: 'minion',
    alternativeAnswers: ''
  },
  {
    name: 'minotaur',
    alternativeAnswers: ''
  },
  {
    name: 'mint',
    alternativeAnswers: ''
  },
  {
    name: 'miracle',
    alternativeAnswers: ''
  },
  {
    name: 'mobile phone',
    alternativeAnswers: 'mobile, cell, cell phone'
  },
  {
    name: 'mohawk',
    alternativeAnswers: ''
  },
  {
    name: 'mole',
    alternativeAnswers: ''
  },
  {
    name: 'molecule',
    alternativeAnswers: ''
  },
  {
    name: 'mona lisa',
    alternativeAnswers: ''
  },
  {
    name: 'monday',
    alternativeAnswers: ''
  },
  {
    name: 'money',
    alternativeAnswers: ''
  },
  {
    name: 'monk',
    alternativeAnswers: ''
  },
  {
    name: 'monkey',
    alternativeAnswers: ''
  },
  {
    name: 'monopoly',
    alternativeAnswers: ''
  },
  {
    name: 'monster',
    alternativeAnswers: ''
  },
  {
    name: 'month',
    alternativeAnswers: ''
  },
  {
    name: 'mood',
    alternativeAnswers: ''
  },
  {
    name: 'moon',
    alternativeAnswers: ''
  },
  {
    name: 'moose',
    alternativeAnswers: ''
  },
  {
    name: 'morse code',
    alternativeAnswers: 'morse, morsecode'
  },
  {
    name: 'mortgage',
    alternativeAnswers: ''
  },
  {
    name: 'mosaic',
    alternativeAnswers: ''
  },
  {
    name: 'mosquito',
    alternativeAnswers: ''
  },
  {
    name: 'moth',
    alternativeAnswers: ''
  },
  {
    name: 'motorbike',
    alternativeAnswers: ''
  },
  {
    name: 'mount everest',
    alternativeAnswers: 'everest, mt everest'
  },
  {
    name: 'mouse',
    alternativeAnswers: ''
  },
  {
    name: 'mouth',
    alternativeAnswers: ''
  },
  {
    name: 'mozart',
    alternativeAnswers: ''
  },
  {
    name: 'mud',
    alternativeAnswers: ''
  },
  {
    name: 'mug',
    alternativeAnswers: ''
  },
  {
    name: 'mummy',
    alternativeAnswers: ''
  },
  {
    name: 'murder',
    alternativeAnswers: ''
  },
  {
    name: 'muscle',
    alternativeAnswers: ''
  },
  {
    name: 'mushroom',
    alternativeAnswers: 'mushrooms'
  },
  {
    name: 'music',
    alternativeAnswers: ''
  },
  {
    name: 'musical',
    alternativeAnswers: ''
  },
  {
    name: 'mustache',
    alternativeAnswers: ''
  },
  {
    name: 'myth',
    alternativeAnswers: ''
  },
  {
    name: 'nail',
    alternativeAnswers: ''
  },
  {
    name: 'nappy',
    alternativeAnswers: 'nappies'
  },
  {
    name: 'narwhal',
    alternativeAnswers: ''
  },
  {
    name: 'nasa',
    alternativeAnswers: ''
  },
  {
    name: 'nature',
    alternativeAnswers: ''
  },
  {
    name: 'navy',
    alternativeAnswers: ''
  },
  {
    name: 'neck',
    alternativeAnswers: ''
  },
  {
    name: 'needle',
    alternativeAnswers: ''
  },
  {
    name: 'neptune',
    alternativeAnswers: ''
  },
  {
    name: 'nerd',
    alternativeAnswers: ''
  },
  {
    name: 'nest',
    alternativeAnswers: ''
  },
  {
    name: 'network',
    alternativeAnswers: ''
  },
  {
    name: 'new zealand',
    alternativeAnswers: ''
  },
  {
    name: 'newspaper',
    alternativeAnswers: ''
  },
  {
    name: 'nightclub',
    alternativeAnswers: ''
  },
  {
    name: 'ninja',
    alternativeAnswers: ''
  },
  {
    name: 'noodle',
    alternativeAnswers: 'noodles'
  },
  {
    name: 'northern lights',
    alternativeAnswers: 'aurora, aurora borealis'
  },
  {
    name: 'norway',
    alternativeAnswers: ''
  },
  {
    name: 'nose',
    alternativeAnswers: ''
  },
  {
    name: 'nose hair',
    alternativeAnswers: 'nosehair'
  },
  {
    name: 'nostril',
    alternativeAnswers: 'nostrils'
  },
  {
    name: 'note',
    alternativeAnswers: ''
  },
  {
    name: 'notebook',
    alternativeAnswers: ''
  },
  {
    name: 'nuclear',
    alternativeAnswers: ''
  },
  {
    name: 'nugget',
    alternativeAnswers: ''
  },
  {
    name: 'number',
    alternativeAnswers: ''
  },
  {
    name: 'nun',
    alternativeAnswers: ''
  },
  {
    name: 'nurse',
    alternativeAnswers: ''
  },
  {
    name: 'nutella',
    alternativeAnswers: ''
  },
  {
    name: 'nutmeg',
    alternativeAnswers: ''
  },
  {
    name: 'oak',
    alternativeAnswers: ''
  },
  {
    name: 'oar',
    alternativeAnswers: ''
  },
  {
    name: 'observatory',
    alternativeAnswers: ''
  },
  {
    name: 'ocean',
    alternativeAnswers: 'sea'
  },
  {
    name: 'octagon',
    alternativeAnswers: ''
  },
  {
    name: 'octopus',
    alternativeAnswers: ''
  },
  {
    name: 'oil',
    alternativeAnswers: ''
  },
  {
    name: 'old',
    alternativeAnswers: ''
  },
  {
    name: 'omelette',
    alternativeAnswers: ''
  },
  {
    name: 'open',
    alternativeAnswers: ''
  },
  {
    name: 'opera',
    alternativeAnswers: ''
  },
  {
    name: 'orange',
    alternativeAnswers: ''
  },
  {
    name: 'orangutan',
    alternativeAnswers: ''
  },
  {
    name: 'orbit',
    alternativeAnswers: ''
  },
  {
    name: 'orchestra',
    alternativeAnswers: ''
  },
  {
    name: 'orchid',
    alternativeAnswers: ''
  },
  {
    name: 'organ',
    alternativeAnswers: ''
  },
  {
    name: 'organic',
    alternativeAnswers: ''
  },
  {
    name: 'origami',
    alternativeAnswers: ''
  },
  {
    name: 'ostrich',
    alternativeAnswers: ''
  },
  {
    name: 'otter',
    alternativeAnswers: ''
  },
  {
    name: 'oval',
    alternativeAnswers: ''
  },
  {
    name: 'oven',
    alternativeAnswers: ''
  },
  {
    name: 'owl',
    alternativeAnswers: ''
  },
  {
    name: 'oxygen',
    alternativeAnswers: ''
  },
  {
    name: 'oyster',
    alternativeAnswers: 'oysters'
  },
  {
    name: 'package',
    alternativeAnswers: ''
  },
  {
    name: 'pacman',
    alternativeAnswers: 'pac-man, pac man'
  },
  {
    name: 'page',
    alternativeAnswers: ''
  },
  {
    name: 'paint',
    alternativeAnswers: ''
  },
  {
    name: 'paintball',
    alternativeAnswers: ''
  },
  {
    name: 'pajamas',
    alternativeAnswers: ''
  },
  {
    name: 'palace',
    alternativeAnswers: ''
  },
  {
    name: 'palm',
    alternativeAnswers: ''
  },
  {
    name: 'palm tree',
    alternativeAnswers: ''
  },
  {
    name: 'pan',
    alternativeAnswers: ''
  },
  {
    name: 'pancake',
    alternativeAnswers: ''
  },
  {
    name: 'panda',
    alternativeAnswers: ''
  },
  {
    name: 'panther',
    alternativeAnswers: ''
  },
  {
    name: 'pants',
    alternativeAnswers: ''
  },
  {
    name: 'paper',
    alternativeAnswers: ''
  },
  {
    name: 'parachute',
    alternativeAnswers: ''
  },
  {
    name: 'parallel',
    alternativeAnswers: ''
  },
  {
    name: 'paris',
    alternativeAnswers: ''
  },
  {
    name: 'park',
    alternativeAnswers: ''
  },
  {
    name: 'parliament',
    alternativeAnswers: ''
  },
  {
    name: 'parrot',
    alternativeAnswers: ''
  },
  {
    name: 'particle',
    alternativeAnswers: ''
  },
  {
    name: 'party',
    alternativeAnswers: ''
  },
  {
    name: 'passport',
    alternativeAnswers: ''
  },
  {
    name: 'password',
    alternativeAnswers: ''
  },
  {
    name: 'pasta',
    alternativeAnswers: ''
  },
  {
    name: 'pastry',
    alternativeAnswers: ''
  },
  {
    name: 'pat',
    alternativeAnswers: ''
  },
  {
    name: 'path',
    alternativeAnswers: ''
  },
  {
    name: 'pattern',
    alternativeAnswers: ''
  },
  {
    name: 'paw',
    alternativeAnswers: ''
  },
  {
    name: 'peach',
    alternativeAnswers: 'peaches'
  },
  {
    name: 'peacock',
    alternativeAnswers: ''
  },
  {
    name: 'peanut',
    alternativeAnswers: 'peanuts'
  },
  {
    name: 'pear',
    alternativeAnswers: ''
  },
  {
    name: 'peas',
    alternativeAnswers: ''
  },
  {
    name: 'pedal',
    alternativeAnswers: ''
  },
  {
    name: 'pelican',
    alternativeAnswers: ''
  },
  {
    name: 'pen',
    alternativeAnswers: ''
  },
  {
    name: 'pencil',
    alternativeAnswers: ''
  },
  {
    name: 'pendulum',
    alternativeAnswers: ''
  },
  {
    name: 'penguin',
    alternativeAnswers: ''
  },
  {
    name: 'pepperoni',
    alternativeAnswers: ''
  },
  {
    name: 'perfume',
    alternativeAnswers: ''
  },
  {
    name: 'periscope',
    alternativeAnswers: ''
  },
  {
    name: 'pet',
    alternativeAnswers: ''
  },
  {
    name: 'photograph',
    alternativeAnswers: 'photo, picture'
  },
  {
    name: 'piano',
    alternativeAnswers: ''
  },
  {
    name: 'piano',
    alternativeAnswers: ''
  },
  {
    name: 'picasso',
    alternativeAnswers: ''
  },
  {
    name: 'pick',
    alternativeAnswers: ''
  },
  {
    name: 'pickaxe',
    alternativeAnswers: ''
  },
  {
    name: 'picnic',
    alternativeAnswers: ''
  },
  {
    name: 'pie',
    alternativeAnswers: ''
  },
  {
    name: 'pier',
    alternativeAnswers: ''
  },
  {
    name: 'pig',
    alternativeAnswers: ''
  },
  {
    name: 'pigeon',
    alternativeAnswers: ''
  },
  {
    name: 'piggy bank',
    alternativeAnswers: ''
  },
  {
    name: 'pikachu',
    alternativeAnswers: ''
  },
  {
    name: 'pillow',
    alternativeAnswers: ''
  },
  {
    name: 'pin',
    alternativeAnswers: ''
  },
  {
    name: 'pineapple',
    alternativeAnswers: ''
  },
  {
    name: 'pinocchio',
    alternativeAnswers: ''
  },
  {
    name: 'pipe',
    alternativeAnswers: ''
  },
  {
    name: 'pirate',
    alternativeAnswers: ''
  },
  {
    name: 'pistachio',
    alternativeAnswers: ''
  },
  {
    name: 'pizza',
    alternativeAnswers: ''
  },
  {
    name: 'planet',
    alternativeAnswers: ''
  },
  {
    name: 'plaster',
    alternativeAnswers: ''
  },
  {
    name: 'plate',
    alternativeAnswers: ''
  },
  {
    name: 'platypus',
    alternativeAnswers: ''
  },
  {
    name: 'playstation',
    alternativeAnswers: ''
  },
  {
    name: 'plough',
    alternativeAnswers: ''
  },
  {
    name: 'plumber',
    alternativeAnswers: ''
  },
  {
    name: 'pluto',
    alternativeAnswers: ''
  },
  {
    name: 'pocket',
    alternativeAnswers: ''
  },
  {
    name: 'poem',
    alternativeAnswers: ''
  },
  {
    name: 'pogo stick',
    alternativeAnswers: 'pogostick'
  },
  {
    name: 'poison',
    alternativeAnswers: ''
  },
  {
    name: 'pokemon',
    alternativeAnswers: ''
  },
  {
    name: 'polar bear',
    alternativeAnswers: ''
  },
  {
    name: 'polish',
    alternativeAnswers: ''
  },
  {
    name: 'poll',
    alternativeAnswers: ''
  },
  {
    name: 'polo',
    alternativeAnswers: ''
  },
  {
    name: 'pond',
    alternativeAnswers: ''
  },
  {
    name: 'pony',
    alternativeAnswers: ''
  },
  {
    name: 'ponytail',
    alternativeAnswers: ''
  },
  {
    name: 'poodle',
    alternativeAnswers: ''
  },
  {
    name: 'popcorn',
    alternativeAnswers: ''
  },
  {
    name: 'pope',
    alternativeAnswers: ''
  },
  {
    name: 'popeye',
    alternativeAnswers: ''
  },
  {
    name: 'poppy',
    alternativeAnswers: ''
  },
  {
    name: 'porcupine',
    alternativeAnswers: ''
  },
  {
    name: 'portal',
    alternativeAnswers: ''
  },
  {
    name: 'portrait',
    alternativeAnswers: ''
  },
  {
    name: 'poseidon',
    alternativeAnswers: ''
  },
  {
    name: 'postcard',
    alternativeAnswers: ''
  },
  {
    name: 'pot',
    alternativeAnswers: ''
  },
  {
    name: 'potato',
    alternativeAnswers: ''
  },
  {
    name: 'potion',
    alternativeAnswers: ''
  },
  {
    name: 'pottery',
    alternativeAnswers: ''
  },
  {
    name: 'pour',
    alternativeAnswers: ''
  },
  {
    name: 'prawn',
    alternativeAnswers: ''
  },
  {
    name: 'pray',
    alternativeAnswers: 'prayer'
  },
  {
    name: 'pregnant',
    alternativeAnswers: ''
  },
  {
    name: 'president',
    alternativeAnswers: ''
  },
  {
    name: 'prince',
    alternativeAnswers: ''
  },
  {
    name: 'princess',
    alternativeAnswers: ''
  },
  {
    name: 'printer',
    alternativeAnswers: ''
  },
  {
    name: 'prism',
    alternativeAnswers: ''
  },
  {
    name: 'profit',
    alternativeAnswers: ''
  },
  {
    name: 'protein',
    alternativeAnswers: ''
  },
  {
    name: 'pub',
    alternativeAnswers: ''
  },
  {
    name: 'puddle',
    alternativeAnswers: ''
  },
  {
    name: 'puffin',
    alternativeAnswers: ''
  },
  {
    name: 'pumpkin',
    alternativeAnswers: ''
  },
  {
    name: 'punch',
    alternativeAnswers: ''
  },
  {
    name: 'punk',
    alternativeAnswers: ''
  },
  {
    name: 'puppet',
    alternativeAnswers: ''
  },
  {
    name: 'puzzle',
    alternativeAnswers: ''
  },
  {
    name: 'pyramid',
    alternativeAnswers: ''
  },
  {
    name: 'quarter',
    alternativeAnswers: ''
  },
  {
    name: 'queen',
    alternativeAnswers: 'the queen'
  },
  {
    name: 'queue',
    alternativeAnswers: ''
  },
  {
    name: 'quilt',
    alternativeAnswers: ''
  },
  {
    name: 'rabbit',
    alternativeAnswers: 'bunny'
  },
  {
    name: 'radar',
    alternativeAnswers: ''
  },
  {
    name: 'radiation',
    alternativeAnswers: ''
  },
  {
    name: 'radio',
    alternativeAnswers: ''
  },
  {
    name: 'raft',
    alternativeAnswers: ''
  },
  {
    name: 'rage',
    alternativeAnswers: ''
  },
  {
    name: 'rain',
    alternativeAnswers: ''
  },
  {
    name: 'rainbow',
    alternativeAnswers: ''
  },
  {
    name: 'raindrop',
    alternativeAnswers: ''
  },
  {
    name: 'rainforest',
    alternativeAnswers: ''
  },
  {
    name: 'raspberry',
    alternativeAnswers: ''
  },
  {
    name: 'ravioli',
    alternativeAnswers: ''
  },
  {
    name: 'razor',
    alternativeAnswers: ''
  },
  {
    name: 'receipt',
    alternativeAnswers: ''
  },
  {
    name: 'recycling',
    alternativeAnswers: 'recycle'
  },
  {
    name: 'reindeer',
    alternativeAnswers: ''
  },
  {
    name: 'remote',
    alternativeAnswers: ''
  },
  {
    name: 'ribbon',
    alternativeAnswers: ''
  },
  {
    name: 'river',
    alternativeAnswers: ''
  },
  {
    name: 'road',
    alternativeAnswers: ''
  },
  {
    name: 'robot',
    alternativeAnswers: ''
  },
  {
    name: 'rocket',
    alternativeAnswers: ''
  },
  {
    name: 'roof',
    alternativeAnswers: ''
  },
  {
    name: 'rose',
    alternativeAnswers: ''
  },
  {
    name: 'royal',
    alternativeAnswers: 'royalty'
  },
  {
    name: 'rugby',
    alternativeAnswers: ''
  },
  {
    name: 'safari',
    alternativeAnswers: ''
  },
  {
    name: 'sailor',
    alternativeAnswers: ''
  },
  {
    name: 'salmon',
    alternativeAnswers: ''
  },
  {
    name: 'sand castle',
    alternativeAnswers: 'sandcastle'
  },
  {
    name: 'sandwich',
    alternativeAnswers: ''
  },
  {
    name: 'santa',
    alternativeAnswers: 'father christmas'
  },
  {
    name: 'satellite',
    alternativeAnswers: ''
  },
  {
    name: 'saturn',
    alternativeAnswers: ''
  },
  {
    name: 'sausage',
    alternativeAnswers: ''
  },
  {
    name: 'saxophone',
    alternativeAnswers: ''
  },
  {
    name: 'scarecrow',
    alternativeAnswers: ''
  },
  {
    name: 'scarf',
    alternativeAnswers: ''
  },
  {
    name: 'scissors',
    alternativeAnswers: ''
  },
  {
    name: 'scotland',
    alternativeAnswers: ''
  },
  {
    name: 'sea',
    alternativeAnswers: 'ocean'
  },
  {
    name: 'seagull',
    alternativeAnswers: ''
  },
  {
    name: 'seahorse',
    alternativeAnswers: ''
  },
  {
    name: 'seal',
    alternativeAnswers: ''
  },
  {
    name: 'seashell',
    alternativeAnswers: ''
  },
  {
    name: 'seaweed',
    alternativeAnswers: ''
  },
  {
    name: 'seesaw',
    alternativeAnswers: ''
  },
  {
    name: 'shark',
    alternativeAnswers: ''
  },
  {
    name: 'shed',
    alternativeAnswers: ''
  },
  {
    name: 'sheep',
    alternativeAnswers: ''
  },
  {
    name: 'shell',
    alternativeAnswers: ''
  },
  {
    name: 'sherlock holmes',
    alternativeAnswers: 'sherlock'
  },
  {
    name: 'shield',
    alternativeAnswers: ''
  },
  {
    name: 'shoe',
    alternativeAnswers: ''
  },
  {
    name: 'shrek',
    alternativeAnswers: ''
  },
  {
    name: 'sing',
    alternativeAnswers: ''
  },
  {
    name: 'skateboard',
    alternativeAnswers: ''
  },
  {
    name: 'skateboard',
    alternativeAnswers: ''
  },
  {
    name: 'ski',
    alternativeAnswers: 'skiing'
  },
  {
    name: 'skyscraper',
    alternativeAnswers: ''
  },
  {
    name: 'sledge',
    alternativeAnswers: 'snow sled, snowsled'
  },
  {
    name: 'sledgehammer',
    alternativeAnswers: ''
  },
  {
    name: 'snail',
    alternativeAnswers: ''
  },
  {
    name: 'snake',
    alternativeAnswers: ''
  },
  {
    name: 'snowflake',
    alternativeAnswers: ''
  },
  {
    name: 'snowman',
    alternativeAnswers: ''
  },
  {
    name: 'social media',
    alternativeAnswers: ''
  },
  {
    name: 'socks',
    alternativeAnswers: 'sock'
  },
  {
    name: 'solar system',
    alternativeAnswers: ''
  },
  {
    name: 'sphinx',
    alternativeAnswers: ''
  },
  {
    name: 'spiderman',
    alternativeAnswers: ''
  },
  {
    name: 'spongebob',
    alternativeAnswers: 'spongebob squarepants'
  },
  {
    name: 'squid',
    alternativeAnswers: ''
  },
  {
    name: 'starfish',
    alternativeAnswers: ''
  },
  {
    name: 'statue of liberty',
    alternativeAnswers: ''
  },
  {
    name: 'stingray',
    alternativeAnswers: ''
  },
  {
    name: 'strawberry',
    alternativeAnswers: ''
  },
  {
    name: 'student',
    alternativeAnswers: ''
  },
  {
    name: 'submarine',
    alternativeAnswers: 'sub'
  },
  {
    name: 'suitcase',
    alternativeAnswers: ''
  },
  {
    name: 'sunflower',
    alternativeAnswers: ''
  },
  {
    name: 'sunglasses',
    alternativeAnswers: ''
  },
  {
    name: 'sushi',
    alternativeAnswers: ''
  },
  {
    name: 'swordfish',
    alternativeAnswers: ''
  },
  {
    name: 'tablet',
    alternativeAnswers: ''
  },
  {
    name: 'tadpole',
    alternativeAnswers: ''
  },
  {
    name: 'tarantula',
    alternativeAnswers: ''
  },
  {
    name: 'tattoo',
    alternativeAnswers: 'tattoos'
  },
  {
    name: 'tea',
    alternativeAnswers: ''
  },
  {
    name: 'teapot',
    alternativeAnswers: ''
  },
  {
    name: 'television',
    alternativeAnswers: 'tv, tele'
  },
  {
    name: 'tennis',
    alternativeAnswers: ''
  },
  {
    name: 'tentacle',
    alternativeAnswers: ''
  },
  {
    name: 'tetris',
    alternativeAnswers: ''
  },
  {
    name: 'thor',
    alternativeAnswers: ''
  },
  {
    name: 'thumb',
    alternativeAnswers: ''
  },
  {
    name: 'tie',
    alternativeAnswers: ''
  },
  {
    name: 'time',
    alternativeAnswers: ''
  },
  {
    name: 'titanic',
    alternativeAnswers: ''
  },
  {
    name: 'toad',
    alternativeAnswers: ''
  },
  {
    name: 'toast',
    alternativeAnswers: ''
  },
  {
    name: 'tomato',
    alternativeAnswers: ''
  },
  {
    name: 'tornado',
    alternativeAnswers: ''
  },
  {
    name: 'torpedo',
    alternativeAnswers: ''
  },
  {
    name: 'tortoise',
    alternativeAnswers: ''
  },
  {
    name: 'totem',
    alternativeAnswers: 'totem pole, totempole'
  },
  {
    name: 'tower of pisa',
    alternativeAnswers: 'pisa, leaning tower of pisa, pisa tower'
  },
  {
    name: 'tractor',
    alternativeAnswers: ''
  },
  {
    name: 'treasure',
    alternativeAnswers: ''
  },
  {
    name: 'tree',
    alternativeAnswers: ''
  },
  {
    name: 'trombone',
    alternativeAnswers: ''
  },
  {
    name: 'tuba',
    alternativeAnswers: ''
  },
  {
    name: 'turtle',
    alternativeAnswers: ''
  },
  {
    name: 'tuxedo',
    alternativeAnswers: ''
  },
  {
    name: 'ufo',
    alternativeAnswers: ''
  },
  {
    name: 'ukulele',
    alternativeAnswers: ''
  },
  {
    name: 'umbrella',
    alternativeAnswers: ''
  },
  {
    name: 'unibrow',
    alternativeAnswers: 'monobrow'
  },
  {
    name: 'uranus',
    alternativeAnswers: ''
  },
  {
    name: 'usb',
    alternativeAnswers: ''
  },
  {
    name: 'vaccine',
    alternativeAnswers: ''
  },
  {
    name: 'vacuum',
    alternativeAnswers: ''
  },
  {
    name: 'vain',
    alternativeAnswers: ''
  },
  {
    name: 'valley',
    alternativeAnswers: ''
  },
  {
    name: 'vampire',
    alternativeAnswers: ''
  },
  {
    name: 'vanilla',
    alternativeAnswers: ''
  },
  {
    name: 'vegetable',
    alternativeAnswers: ''
  },
  {
    name: 'vine',
    alternativeAnswers: ''
  },
  {
    name: 'virus',
    alternativeAnswers: ''
  },
  {
    name: 'vodka',
    alternativeAnswers: ''
  },
  {
    name: 'voice',
    alternativeAnswers: ''
  },
  {
    name: 'volcano',
    alternativeAnswers: ''
  },
  {
    name: 'voodoo',
    alternativeAnswers: 'voodoo doll'
  },
  {
    name: 'waffle',
    alternativeAnswers: ''
  },
  {
    name: 'walk',
    alternativeAnswers: ''
  },
  {
    name: 'wasp',
    alternativeAnswers: ''
  },
  {
    name: 'watch',
    alternativeAnswers: ''
  },
  {
    name: 'water',
    alternativeAnswers: ''
  },
  {
    name: 'wave',
    alternativeAnswers: ''
  },
  {
    name: 'website',
    alternativeAnswers: ''
  },
  {
    name: 'weight',
    alternativeAnswers: ''
  },
  {
    name: 'werewolf',
    alternativeAnswers: ''
  },
  {
    name: 'wheelbarrow',
    alternativeAnswers: ''
  },
  {
    name: 'whip',
    alternativeAnswers: ''
  },
  {
    name: 'whisk',
    alternativeAnswers: ''
  },
  {
    name: 'wig',
    alternativeAnswers: ''
  },
  {
    name: 'william shakespeare',
    alternativeAnswers: 'shakespeare'
  },
  {
    name: 'windmill',
    alternativeAnswers: ''
  },
  {
    name: 'window',
    alternativeAnswers: ''
  },
  {
    name: 'windscreen',
    alternativeAnswers: ''
  },
  {
    name: 'wine',
    alternativeAnswers: ''
  },
  {
    name: 'winter',
    alternativeAnswers: ''
  },
  {
    name: 'witch',
    alternativeAnswers: ''
  },
  {
    name: 'wizard',
    alternativeAnswers: ''
  },
  {
    name: 'wolf',
    alternativeAnswers: ''
  },
  {
    name: 'wolverine',
    alternativeAnswers: ''
  },
  {
    name: 'wood',
    alternativeAnswers: ''
  },
  {
    name: 'woodpecker',
    alternativeAnswers: ''
  },
  {
    name: 'world',
    alternativeAnswers: ''
  },
  {
    name: 'worm',
    alternativeAnswers: 'worms'
  },
  {
    name: 'xylophone',
    alternativeAnswers: ''
  },
  {
    name: 'yacht',
    alternativeAnswers: ''
  },
  {
    name: 'yeti',
    alternativeAnswers: 'meh-teh, mehteh, abominable snowman'
  },
  {
    name: 'yoda',
    alternativeAnswers: ''
  },
  {
    name: 'yoshi',
    alternativeAnswers: ''
  },
  {
    name: 'zebra',
    alternativeAnswers: ''
  },
  {
    name: 'zelda',
    alternativeAnswers: ''
  },
  {
    name: 'zeppelin',
    alternativeAnswers: 'airship'
  },
  {
    name: 'zeus',
    alternativeAnswers: ''
  },
  {
    name: 'zombie',
    alternativeAnswers: 'zombies'
  },
  {
    name: 'zoo',
    alternativeAnswers: ''
  },
  {
    name: 'zoom',
    alternativeAnswers: ''
  }
];

export default words;
