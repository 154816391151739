<template>
  <div class="game-options">
    <div>
      <h3>Current game options</h3>
      <p v-if="!getRoomAdmin">Can be changed by the host.</p>
      <div class="form-control">
        <div class="option">
          <div class="option-description">
            <label for="voting-timer-select"
              ><font-awesome-icon icon="clock" /> Voting Round Timer</label
            >
            <p>
              How long the voting round should last.
            </p>
          </div>
          <div class="option-setting">
            <select
              v-if="getRoomAdmin"
              v-model="selectedTimerOption"
              @change="setSelectedVotingTimer"
              id="voting-timer-select"
            >
              <option
                v-for="option in votingTimerOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.name }}
              </option>
            </select>
            <p v-else>{{ calculateVotingTimer }}</p>
          </div>
        </div>
      </div>
      <div class="form-control">
        <div class="option">
          <div class="option-description">
            <label for="voting-grace-select"
              ><font-awesome-icon icon="vote-yea" /> Voting Round Grace</label
            >
            <p>
              How many rounds to play first before voting starts.
            </p>
          </div>
          <div class="option-setting">
            <select
              v-if="getRoomAdmin"
              v-model="selectedVotingGrace"
              @change="setSelectedVotingGrace"
              id="voting-grace-select"
            >
              <option
                v-for="option in votingGraceOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.name }}
              </option>
            </select>
            <p v-else>{{ calculateVotingGrace }}</p>
          </div>
        </div>
      </div>
      <div class="form-control">
        <div class="option custom-words">
          <div class="option-description">
            <label for="custom-words"
              ><font-awesome-icon icon="envelope-open-text" /> Custom
              Words</label
            >
            <p>
              Custom words to be used during the game, separated by a comma.
            </p>
          </div>
          <div class="option-setting">
            <div v-if="getRoomAdmin">
              <textarea
                :value="customWords"
                @input="updateCustomWords"
                placeholder="Enter words separated by a comma"
                id="custom-words"
              ></textarea>
              <label for="customWordsOnly"
                ><input
                  type="checkbox"
                  id="customWordsOnly"
                  :value="customWordsOnlyCheckbox"
                  :checked="customWordsOnlyCheckbox"
                  @change="updateCustomWordsOnlyCheckbox"
                />Only use custom words</label
              >
            </div>
            <p v-else>Custom words can only be set by the host.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedTimerOption: 60,
      votingTimerOptions: [
        { name: '30 seconds', value: 30 },
        { name: '1 minute', value: 60 },
        { name: '1 minute 30 seconds', value: 90 },
        { name: '2 minutes', value: 120 }
      ],
      selectedVotingGrace: 1,
      votingGraceOptions: [
        { name: '1 round', value: 1 },
        { name: '2 rounds', value: 2 },
        { name: '3 rounds', value: 3 }
      ]
    };
  },
  sockets: {
    update_players() {
      // ensure joining players have correct game options
      if (this.getRoomAdmin) {
        this.$socket.client.emit(
          'set_voting_timer',
          this.getCurrentRoom,
          this.selectedTimerOption
        );
        this.$socket.client.emit(
          'set_voting_grace',
          this.getCurrentRoom,
          this.selectedVotingGrace
        );
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentRoom',
      'getRoomAdmin',
      'getVotingTimer',
      'getVotingRoundGrace',
      'getCustomWords',
      'getUseCustomWordsOnly'
    ]),
    calculateVotingTimer() {
      let duration = this.getVotingTimer;
      let minText = 'minutes';

      let min = parseInt(duration / 60);
      duration = duration % 60;

      let sec = parseInt(duration);

      if (sec < 10) {
        sec = `0${sec}`;
      }
      if (min == 1) {
        minText = 'minute';
      }

      if (min == 0) {
        return `${sec} seconds`;
      } else if (sec == 0 && min !== 0) {
        return `${min} ${minText}`;
      } else {
        return `${min} ${minText} ${sec} seconds`;
      }
    },
    calculateVotingGrace() {
      let roundNumber = this.getVotingRoundGrace;
      let roundText = 'rounds';

      if (roundNumber == 1) {
        roundText = 'round';
      }

      return `${roundNumber} ${roundText}`;
    },
    customWords() {
      const customWordsList = this.$store.state.customWords;
      return customWordsList;
    },
    customWordsOnlyCheckbox() {
      return this.$store.state.useCustomWordsOnly;
    }
  },
  methods: {
    ...mapActions(['setVotingTimer', 'setVotingRoundGrace']),
    setSelectedVotingTimer() {
      this.$socket.client.emit(
        'set_voting_timer',
        this.getCurrentRoom,
        this.selectedTimerOption
      );
    },
    setSelectedVotingGrace() {
      this.$socket.client.emit(
        'set_voting_grace',
        this.getCurrentRoom,
        this.selectedVotingGrace
      );
    },
    updateCustomWords(event) {
      let separatedWords = event.target.value.split(',');

      let wordsPayload = [];
      for (let i = 0; i < separatedWords.length; i++) {
        wordsPayload.push(separatedWords[i]);
      }

      this.$store.commit('CUSTOM_WORDS', wordsPayload);
    },
    updateCustomWordsOnlyCheckbox(event) {
      this.$store.commit('USE_CUSTOM_WORDS_ONLY', event.target.checked);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.form-control {
  margin-bottom: 2rem;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}

.option.custom-words {
  flex-direction: column;
  align-items: stretch;
}

.option-description {
  flex: 1 1 50%;
  margin-right: 2rem;
}

.option-description p {
  font-size: 1rem;
  line-height: 1.25rem;
}

.form-control p {
  margin-bottom: 0;
}

label {
  font-size: 1.25rem;
  font-weight: 700;
}

.option-setting {
  flex: 1 1 50%;
}

.option.custom-words .option-description p {
  margin: 0 0 0.5rem 0;
}

select {
  min-height: 2.5rem;
  border-radius: var(--rounded-corner-small);
  border: 2px solid var(--dark-500);
  outline: none;
  background: var(--dark-500);
  color: var(--light-white);
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1.6rem;
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.1s ease-in-out;
  width: 100%;
}

select:focus {
  border: 2px solid var(--primary-blue);
}

textarea {
  margin: 0;
  padding: 1rem;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6rem;
  text-align: left;
  text-transform: none;
  background: var(--dark-500);
  color: var(--light-white);
  outline: none;
  width: 100%;
  border: 2px solid var(--dark-500);
  border-radius: var(--rounded-corner-small);
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
}

textarea:focus {
  border: 2px solid var(--primary-blue);
}

textarea::placeholder {
  color: var(--light-200);
}

textarea:focus::placeholder {
  opacity: 0;
}

input[type='checkbox'] {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.option-setting p {
  border-radius: var(--rounded-corner-small);
  border: 2px solid var(--dark-500);
  outline: none;
  background: transparent;
  color: var(--light-white);
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1.6rem;
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.1s ease-in-out;
}
</style>
