<template>
  <div class="form-control" :class="{ invalid: error }">
      <select v-model="selected">
          <option v-for="option in options" :value="option.value" :key="option.value">
              {{ option.text }}
          </option>
      </select>
  </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>