<template>
  <the-header></the-header>
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';

export default {
  name: 'Secret Artist',
  components: {
    TheHeader
  },
  sockets: {
    connect() {
      console.log('Connected');
    },
  },
};
</script>

<style>
@import url('https://use.typekit.net/hdm4qgs.css');
@import './assets/css/root.css';

html, body {
  margin: 0;
  padding: 0;
}

body {
  background: var(--dark-800);
  font-family: var(--font-family-1);
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: var(--light-100);
}

.container {
  /* max-width: var(--container-width);
  margin: 0 auto; */
  margin: 2.5rem 0 0 0;
  padding: 0 2rem;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .container {
    margin: 1rem 0 0 0;
  }
}

.container.center {
  flex-direction: column;
  justify-content: center;
}

.content {
  flex: 0 1 30em;
  margin: 1rem;
  /* width: 30em;
  margin-bottom: 2rem; */
}

.content.right {
  flex: 0 1 20em;
  /* width: 20em; */
}

.content.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

h1,
h2,
h3 {
  font-family: var(--font-family-2);
  font-weight: 400;
  font-style: normal;
  color: var(--light-white);
  margin: 0 0 1em 0;
}
h1 {
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
}
h2 {
  font-size: 2.2rem;
  line-height: 2.2rem;
}
h3 {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

p {
  font-size: 1.25rem;
  margin: 0 0 1.5em 0;
}

a {
  color: var(--primary-green);
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.1s ease-out;
}

.route-enter-active {
  transition: all 0.1s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
