<template>
  <teleport to="body">
    <div v-if="show" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <h2>{{ title }}</h2>
          <span
            v-if="!getVotingFinished"
            class="timeleft"
            :class="{ attention: timeRunningOut }"
            >{{ timeleft }}</span
          >
        </header>
        <section>
          <div v-if="!getVotingFinished">
            <p class="players-voted">Players voted: {{ playersVoted }}</p>
            <ul>
              <li v-for="player in getPlayers" :key="player.id">
                <base-button
                  :mode="playerColourCalc(player.colour)"
                  @click="voteForPlayer(player.id)"
                  ><span class="voted-icon" :ref="player.id"
                    ><font-awesome-icon icon="vote-yea" /> Your Vote</span
                  >{{ player.username }}</base-button
                >
              </li>
            </ul>
            <div>
              <base-button mode="grey" @click="skipVote"
                ><span class="voted-skipped-icon" ref="skippedVote"
                  ><font-awesome-icon icon="vote-yea" v-if="skippedVote"
                /></span>
                {{ !skippedVote ? 'Skip' : 'Skipped' }} Vote</base-button
              >
            </div>
          </div>
          <div v-else>
            <div
              v-if="
                getVotingFinished === true && getSecretArtistFound === false
              "
            >
              <p>The Secret Artist was not found.</p>
              <base-button
                v-if="getRoomAdmin"
                @click="startNewRound"
                class="center"
                ><font-awesome-icon icon="play" /> Start new round</base-button
              >
              <p v-else>Please wait for the host to continue the game.</p>
            </div>
            <div v-else-if="getVotingFinished === true && getSecretArtistFound">
              <p>The Secret Artist was found!</p>
              <p>It was {{ secretArtistName }}</p>
              <p>
                {{ isPlayerSecretArtist ? 'You' : 'They' }} now have the chance
                to guess the correct word to win the round...
              </p>
              <base-spinner v-if="!isPlayerSecretArtist"></base-spinner>
              <div v-else-if="isPlayerSecretArtist">
                <form @submit.prevent="secretArtistGuess">
                  <base-input
                    id="secretArtistGuessInput"
                    type="text"
                    placeholder="The word is..."
                    labelMode="hidden"
                    v-model.trim="secretArtistGuessInput"
                  ></base-input>
                  <base-button mode="green" class="center"
                    ><font-awesome-icon icon="paper-plane" /> Submit
                    answer</base-button
                  >
                </form>
                <p class="or">or</p>
                <base-button @click="iDontKnow" mode="grey" class="center"
                  ><font-awesome-icon icon="question-circle" /> I don't
                  know</base-button
                >
              </div>
            </div>
          </div>
        </section>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      timeleft: null,
      timeRunningOut: false,
      playerColour: 'green',
      votePlayerId: null,
      skippedVote: false,
      secretArtistGuessInput: null
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  watch: {
    getVotingRound(value) {
      if (value === true) {
        this.timeleft = this.getVotingTimer;
      }
    },
    timeleft: {
      handler(value) {
        if (value > 0 && this.getVotes.length !== this.getPlayers.length) {
          setTimeout(() => {
            this.timeleft--;
          }, 1000);
        }
        if (value <= 10) {
          this.timeRunningOut = true;
        }
        if (value == 0 || this.getVotes.length == this.getPlayers.length) {
          if (this.getRoomAdmin) {
            this.voteCalc();
          }
          this.votePlayerId = null;
          this.timeRunningOut = false;
          this.skippedVote = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPlayers',
      'getCurrentRoom',
      'getCurrentWord',
      'getVotingRound',
      'getVotingFinished',
      'getVotingTimer',
      'getVotes',
      'getRoomAdmin',
      'getSecretArtist',
      'getSecretArtistFound'
    ]),
    isPlayerSecretArtist() {
      if (
        this.getSecretArtist &&
        this.getSecretArtist.id === this.$socket.client.id
      ) {
        return true;
      } else {
        return false;
      }
    },
    secretArtistName() {
      const secretArtistName = this.getPlayers.find(
        p => p.secretArtist === true
      ).username;
      return secretArtistName;
    },
    playersVoted() {
      let totalNumberOfPlayers = this.getPlayers.length;
      let totalVotes = this.getVotes.length;
      return totalVotes + '/' + totalNumberOfPlayers;
    }
  },
  methods: {
    ...mapActions(['setVotingRound', 'setWinner']),
    voteForPlayer(playerId) {
      if (!this.votePlayerId && !this.skippedVote) {
        this.votePlayerId = playerId;
        this.$socket.client.emit('vote_player', this.getCurrentRoom, playerId);
        this.$refs[playerId].classList.add('selected');
      }
    },
    skipVote() {
      if (!this.votePlayerId && !this.skippedVote) {
        this.skippedVote = true;
        this.$socket.client.emit('vote_player', this.getCurrentRoom, null);
        this.$refs.skippedVote.classList.add('selected');
      }
    },
    startNewRound() {
      if (!this.getRoomAdmin) {
        return;
      }
      this.$socket.client.emit('new_round', this.getCurrentRoom);
    },
    voteCalc() {
      this.$socket.client.emit('vote_calc', this.getCurrentRoom, this.getVotes);
    },
    secretArtistGuess() {
      let guess = this.secretArtistGuessInput;
      if (guess !== '' && guess !== null) {
        guess = guess.toLowerCase();
      }

      const alternativeAnswers = this.getCurrentWord.alternativeAnswers.split(
        /\s*,\s*/
      );

      if (
        (guess !== '' && guess === this.getCurrentWord.name) ||
        alternativeAnswers.includes(guess)
      ) {
        const winner = {
          flag: true,
          result: 'secretartist',
          guess: guess
        };
        this.$socket.client.emit('winner', this.getCurrentRoom, winner);
      } else {
        const winner = {
          flag: true,
          result: 'otherplayers',
          guess: guess
        };
        this.$socket.client.emit('winner', this.getCurrentRoom, winner);
      }
    },
    iDontKnow() {
      const winner = {
        flag: true,
        result: 'otherplayers'
      };
      this.$socket.client.emit('winner', this.getCurrentRoom, winner);
    },
    playerColourCalc(input) {
      switch (input) {
        case '#EE4266':
          return 'red';
        case '#FFC30F':
          return 'yellow';
        case '#4056F4':
          return 'blue';
        case '#35CE8D':
          return 'green';
        case '#5C17B5':
          return 'purple';
        case '#AD33FF':
          return 'electricpurple';
        case '#F27618':
          return 'orange';
        case '#1DD8ED':
          return 'turqoise';

        default:
          return 'green';
      }
    }
  }
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

dialog {
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 999;
  border-radius: var(--rounded-corner-medium);
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: var(--dark-600);
  color: white;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-700);
  width: calc(100% - 3em);
  padding: 1.5rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 2em 1em;
  font-family: var(--font-family-1);
  font-size: 1.25rem;
  line-height: 1.6rem;
}

ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2rem 0;
  padding: 0;
}

li {
  list-style: none;
  margin: 0.5rem;
  width: calc(50% - 1rem);
}

ul li button {
  align-items: center;
  justify-content: center;
  border-radius: var(--rounded-corner-small);
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 2rem 1rem;
  font-size: 1.6rem;
  width: 100%;
}

ul li button::after {
  border-radius: var(--rounded-corner-small);
}

.voted-icon,
.voted-skipped-icon {
  display: none;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 400;
}

.voted-icon.selected {
  display: block;
}

.voted-skipped-icon.selected {
  display: inline-block;
}

.voted-skipped-icon {
  position: relative;
  top: 0;
  left: 0;
}

.players-voted {
  margin: 0 0 1rem 0.5rem;
}

.timeleft {
  font-size: 1.6rem;
}

.attention {
  color: var(--primary-red);
}

.center {
  display: flex;
}

.button-group {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  text-align: center;
}

.button-group button {
  margin: 0;
}

.or {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
}

.or:before,
.or:after {
  content: '';
  flex: 1 1;
  border-bottom: 2px solid var(--dark-700);
  margin: auto;
}

.or:before {
  margin-right: 1rem;
}

.or:after {
  margin-left: 1rem;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 480px) {
  dialog {
    top: 2%;
    left: 2%;
    width: 96%;
  }
  li {
    list-style: none;
    margin: 0.25rem;
    width: calc(100% - 1rem);
  }
  ul li button {
    padding: 1rem 0.5rem;
  }
  .voted-icon,
  .voted-skipped-icon {
    font-size: 1rem;
    line-height: 1rem;
    top: 0.25rem;
    left: 0.25rem;
  }
}

@media (min-width: 768px) {
  dialog {
    top: 20vh;
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
