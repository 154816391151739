<template>
  <section>
    <div class="container">
      <div class="content">
        <h2>How to play</h2>
        <p>
          All players except the Secret Artist are given the same word that they
          collectively visualise, taking it in turns to draw one line at a time.
        </p>

        <p>
          At the end of a round, all players have a chance to discuss and
          identify the Secret Artist.
        </p>

        <p>
          If the other players guess who the Secret Artist is, the Secret Artist
          has the opportunity to win by correctly guessing the word.
        </p>

        <span class="tag"><font-awesome-icon icon="user" /> 3-8 Players</span>
        <span class="tag"><font-awesome-icon icon="paint-brush" /> Questionable artistic talent</span>
      </div>

      <div class="content right center">
        <game-username v-if="!isUsernameSelected"></game-username>
        <game-select v-else></game-select>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GameUsername from '../components/game/GameUsername.vue';
import GameSelect from '../components/game/GameSelect.vue';

export default {
  components: {
    GameUsername,
    GameSelect
  },
  computed: {
    ...mapGetters(['isUsernameSelected'])
  },
  methods: {
    ...mapActions(['setGameStage', 'socket_leftRoom', 'resetKicked'])
  },
  created() {
    this.setGameStage('welcome');
    this.socket_leftRoom();
    this.resetKicked();
  }
};
</script>

<style scoped>
.tag {
  padding: 0.2rem 0.6rem 0.3rem;
  margin: 0.2rem;
  background: var(--dark-500);
  border-radius: var(--rounded-corner-small);
  font-size: 1.25rem;
  white-space: nowrap;
}
</style>
