import { createApp } from 'vue';
import { io } from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUndoAlt,
  faPaintBrush,
  faPalette,
  faCrown,
  faUser,
  faVoteYea,
  faCopy,
  faTimesCircle,
  faPlay,
  faSignOutAlt,
  faStepForward,
  faPaperPlane,
  faClock,
  faQuestionCircle,
  faCaretDown,
  faEnvelopeOpenText
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faUndoAlt,
  faPaintBrush,
  faPalette,
  faCrown,
  faUser,
  faVoteYea,
  faCopy,
  faTimesCircle,
  faPlay,
  faSignOutAlt,
  faStepForward,
  faPaperPlane,
  faClock,
  faQuestionCircle,
  faCaretDown,
  faEnvelopeOpenText
);

import BaseButton from './components/ui/BaseButton.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BaseBurger from './components/ui/BaseBurger.vue';
import BaseSidebar from './components/ui/BaseSidebar.vue';
import BaseInput from './components/ui/BaseInput.vue';
import BaseDropdown from './components/ui/BaseDropdown.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseAccordion from './components/ui/BaseAccordion.vue';

let ioUrl = 'https://afternoon-bayou-24533.herokuapp.com/';
if(process.env.NODE_ENV !== 'production') {
  ioUrl = 'http://localhost:3000';
}

const socket = io(ioUrl);

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueSocketIOExt, socket, { store });

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-burger', BaseBurger);
app.component('base-sidebar', BaseSidebar);
app.component('base-input', BaseInput);
app.component('base-dropdown', BaseDropdown);
app.component('base-spinner', BaseSpinner);
app.component('base-accordion', BaseAccordion);

app.mount('#app');
