<template>
  <div class="game-notification-container">
    <transition name="backdrop">
    <div v-if="show" class="backdrop"></div>
    </transition>
    <transition name="notification">
      <div v-if="show" class="game-notification">
        <header>
          <slot name="header">
            <h2>{{ title }}</h2>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.game-notification {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  /* height: 80%; */
  z-index: 99;
  border-radius: var(--rounded-corner-medium);
  border: none;
  background-color: var(--dark-700);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: white;
  overflow: hidden;
  text-align: center;
}

header {
  background-color: var(--dark-800);
  width: calc(100% - 3em);
  padding: 1.5rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 2em 1em;
  font-family: var(--font-family-1);
  font-size: 1.25rem;
  line-height: 1.6rem;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.notification-enter-active {
  transition: all 0.1s ease-out;
}

.notification-leave-active {
  transition: all 0.1s ease-in;
}

.notification-enter-to,
.notification-leave-from {
  opacity: 1;
  transform: scale(1);
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

.backdrop-enter-active {
  transition: all 0.1s ease-out;
}

.backdrop-leave-active {
  transition: all 0.1s ease-in;
}

.backdrop-enter-to,
.backdrop-leave-from {
  opacity: 1;
}

@media (min-width: 1024px) {
  .game-notification {
    left: calc(50% - 30%);
    top: calc(50% - 30%);
    width: 60%;
    /* height: 60%; */
  }
}
</style>
