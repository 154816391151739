<template>
  <button v-if="!link" :class="mode" :type="type">
    <div>
      <slot></slot>
    </div>
  </button>
  <router-link v-else :to="to" :class="mode">
    <div>
      <slot></slot>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped>
button,
a {
  text-decoration: none;
  text-transform: none;
  border: none;
  background: none;
  color: var(--light-white);
  text-shadow: 0px 1px 5px var(--dark-400);
  padding: 1rem 3rem;
  margin: 0 auto 1.25rem auto;
  font-family: var(--font-family-1);
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  z-index: 10;
}

button::after,
a::after {
  background: var(--primary-green);
  border-radius: var(--rounded-corner-large);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: transform 100ms cubic-bezier(0.345, 0.115, 0.135, 1.42);
}

button .svg-inline--fa {
  filter: drop-shadow(0px 1px 5px var(--dark-400));
}

button .fa-sign-out-alt,
a .fa-sign-out-alt {
  filter: drop-shadow(0px 1px 5px var(--dark-400));
}

button:hover::after,
a:hover::after {
  transform: scale(1.025) scaleY(1.045) perspective(1px);
}

button.green::after,
a.green::after {
  background: var(--primary-green);
}
button.blue::after,
a.blue::after {
  background: var(--primary-blue);
}
button.red::after,
a.red::after {
  background: var(--primary-red);
}
button.yellow::after,
a.yellow::after {
  background: var(--primary-yellow);
}
button.purple::after,
a.purple::after {
  background: var(--primary-purple);
}
button.electricpurple::after,
a.electricpurple::after {
  background: var(--primary-electricpurple);
}
button.orange::after,
a.orange::after {
  background: var(--primary-orange);
}
button.turqoise::after,
a.turqoise::after {
  background: var(--primary-turqoise);
}
button.grey::after,
a.grey::after {
  background: var(--dark-500);
}

.right-of-input {
  margin-left: 0;
  padding: 1rem;
}

.right-of-input::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transform-origin: center left;
}

.small {
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  font-size: 1rem;
}

@media (max-width: 768px) {
  button,
  a {
    padding: 1rem 2rem;
  }
}
</style>
