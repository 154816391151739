<template>
  <section>
    <div class="container">
      <div class="content">
        <h2>Help</h2>
        <div class="faqs">
          <base-accordion :list="faqList"></base-accordion>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqList: [
        {
          id: 1,
          heading: 'Why can I only draw one line?',
          body:
            `This is a deliberate limitation to ensure each player can only draw 
            one line per turn. An undo feature is available in the brush options, 
            and you can undo as many times as you like before ending your turn.`
        },
        {
          id: 2,
          heading: 'Where do I enter the room code?',
          body: `Go to the homepage and enter a username if you have not already 
          done so. You should then see an input for the 4-letter room code next 
          to the Join Game button. Enter the code your game host has given you to 
          join their game.`
        },
        {
          id: 3,
          heading: "I think I've found a bug!",
          body:
            `Oh dear! Please get in touch via the Feedback page with as much 
            detail as you can so I can fix it.`
        }
      ]
    };
  }
};
</script>

<style scoped>
.faqs {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1rem 0;
}
</style>
