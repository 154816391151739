<template>
  <div class="form-control" :class="{ invalid: error }">
    <label :for="id" :class="labelMode">{{ placeholder }}</label>
    <input
      :id="id"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :class="mode"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @blur="$emit('blur', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue', 'blur'],
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    maxlength: {
      type: String,
      required: false
    },
    labelMode: {
      type: String,
      required: false,
      default: null
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped>
.form-control {
  margin-bottom: 20px;
  display: flex;
}

.form-control.invalid input {
  border: 2px solid var(--primary-red);
}

input {
  margin: 0;
  padding: 0 1rem;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  background: var(--dark-500);
  color: var(--light-white);
  outline: none;
  width: 100%;
  height: 54px;
  border: 2px solid var(--dark-500);
  border-radius: 50px;
  transition: all 0.1s ease-in-out;
}

input.inline,
input.room-code {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 0 0 1rem;
  height: 54px;
}

input.inline {
  text-align: left;
}

input.room-code {
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
}

input[type='text']:focus,
.form-control.invalid input:focus {
  border: 2px solid var(--primary-blue);
}

input::placeholder {
  text-transform: none;
  font-weight: 400;
  color: var(--light-200);
}

input[type='text']:focus::placeholder {
  opacity: 0;
}

.form-control.invalid input {
  border: 2px solid var(--primary-red);
}

label {
  font-size: 1.25rem;
  margin: 0 0 5px 0;
}

label.hidden {
  display: none;
}
</style>
