<template>
  <section>
    <base-dialog :show="!!error" title="Username" @close="handleError">
      Please enter a username before continuing.
    </base-dialog>

    <form @submit.prevent="submitUsername">
        <base-input
          id="username"
          type="text"
          placeholder="Enter your username"
          v-model.trim="username.val"
          @blur="clearValidity('username')"
          label-mode="hidden"
          :error="!username.isValid"
        ></base-input>
      <div class="button-group">
        <base-button mode="green" type="submit">That's me</base-button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      username: {
        val: '',
        isValid: true
      },
      usernameValid: true,
      error: false
    };
  },
  computed: {
    ...mapGetters(['isUsernameSelected', 'getUsername'])
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.usernameValid = true;
      if (this.username.val === '') {
        this.username.isValid = false;
        this.usernameValid = false;
      }
    },
    submitUsername() {
      this.validateForm();

      if (!this.usernameValid) {
        this.error = true;
        return;
      }

      this.$store.commit('USERNAME_SELECTED');
      this.$store.commit('USERNAME', this.username.val);
      localStorage.setItem("username", this.username.val);
    },
    handleError() {
      this.error = false;
    }
  },
  mounted() {
    if (localStorage.getItem("username")) {
      this.username.val = localStorage.getItem("username");
    }
  }
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.button-group button {
  width: 100%;
}
</style>
