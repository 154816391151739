<template>
  <section>
    <div class="container">
      <div class="content">
        <h2>About</h2>
        <p>
          Secret Artist is a multiplayer collective drawing game where players
          have to deduce the identity of the Secret Artist before they can guess
          the word.
        </p>
        <p>
          This project was created as a side-project by Joseph Lavington and is
          very much still in development. If you notice any bugs or if you have
          any suggestions, do let me know via the <router-link to="/feedback">Feedback page</router-link>.
        </p>
      </div>
    </div>
  </section>
</template>
