<template>
  <section>
    <base-dialog :show="loading" title="Loading..." :fixed="true">
      <base-spinner></base-spinner>
    </base-dialog>

    <base-dialog :show="!!error" title="Room Code" @close="handleError">
      Please enter a valid room code. Ask your host for the 4-letter code to
      access their game.
    </base-dialog>

    <div class="button-group">
      <base-button mode="green" @click="newGame">New game</base-button>

      <p class="or">or</p>

      <form @submit.prevent="joinGame">
        <div class="join-game-container">
          <base-input
            id="roomCode"
            mode="room-code"
            type="text"
            placeholder="4-letter code"
            maxlength="4"
            @blur="clearValidity('roomCode')"
            labelMode="hidden"
            v-model="roomCode.val"
            :error="!roomCode.isValid"
          ></base-input>
          <base-button mode="blue right-of-input">Join game</base-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      roomCode: {
        val: '',
        isValid: true
      },
      roomCodeValid: true,
      error: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getRoomFound', 'getCurrentRoom', 'getUsername'])
  },
  watch: {
    getRoomFound() {
      // redirect to room when room found
      this.roomCodeValid = true;
      if (this.getRoomFound === false) {
        this.error = true;
        this.roomCode.isValid = false;
        this.roomCodeValid = false;
      } else if (this.getRoomFound === true) {
        this.$router.push('/lobby/' + this.getCurrentRoom);
      }
    }
  },
  methods: {
    ...mapActions(['resetRoomFound']),
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.roomCodeValid = true;
      if (this.roomCode.val === '' || this.roomCode.val.length < 4) {
        this.roomCode.isValid = false;
        this.roomCodeValid = false;
      }
    },
    newGame() {
      if (!this.getRoomFound && this.getCurrentRoom === null) {
        this.loading = true;
        // check room isn't already set
        this.$socket.client.emit('NEW_GAME', this.getUsername);
      }
    },
    joinGame() {
      this.validateForm();

      if (!this.roomCodeValid) {
        this.error = true;
        return;
      }

      this.loading = true;

      const roomCodeUppercase = this.roomCode.val.toUpperCase();
      this.$socket.client.emit(
        'JOIN_GAME',
        roomCodeUppercase,
        this.getUsername
      );
    },
    handleError() {
      this.loading = false;
      this.error = false;
      this.resetRoomFound();
    }
  }
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

button {
  width: 100%;
}

.join-game-container {
  display: flex;
}

.join-game-container .form-control,
.join-game-container button {
  width: 50%;
}

.or {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
}

.or:before,
.or:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid var(--dark-600);
  margin: auto;
}

.or:before {
  margin-right: 1rem;
}

.or:after {
  margin-left: 1rem;
}
</style>
