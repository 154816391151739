<template>
  <base-dialog :show="copySuccess" title="Room Code" @close="handleSuccess">
    Room code successfully copied to your clipboard. Showing off your artistic
    talent is potentially seconds away!
  </base-dialog>

  <div class="game-invite">
    <div class="game-invite-heading">
      <h2>Room code</h2>
      <base-button class="copy-code" mode="grey small" @click="copyRoomCode"
        ><font-awesome-icon icon="copy" /> Copy code</base-button
      >
    </div>
    <div class="room-code-container">
      <p class="room-code">{{ getCurrentRoom }}</p>
      <p class="code-nato">{{ natoResult }}</p>
    </div>
    <p>Give this code to people you'd like to play with.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      copySuccess: false
    };
  },
  computed: {
    ...mapGetters(['getCurrentRoom']),
    natoResult() {
      return this.getCurrentRoom
        .split('')
        .map(el => this.nato(el))
        .join(' ');
    }
  },
  methods: {
    nato(string) {
      let cases = {
        A: 'Alpha',
        B: 'Bravo',
        C: 'Charlie',
        D: 'Delta',
        E: 'Echo',
        F: 'Foxtrot',
        G: 'Golf',
        H: 'Hotel',
        I: 'India',
        J: 'Juliett',
        K: 'Kilo',
        L: 'Lima',
        M: 'Mike',
        N: 'November',
        O: 'Oscar',
        P: 'Papa',
        Q: 'Quebec',
        R: 'Romeo',
        S: 'Sierra',
        T: 'Tango',
        U: 'Uniform',
        V: 'Victor',
        W: 'Whiskey',
        X: 'X-ray',
        Y: 'Yankee',
        Z: 'Zulu'
      };
      return cases[string];
    },
    copyRoomCode() {
      const el = document.createElement('textarea');
      el.value = this.getCurrentRoom;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.copySuccess = true;
    },
    handleSuccess() {
      this.copySuccess = false;
    }
  }
};
</script>

<style scoped>
.game-invite {
  position: relative;
  background: var(--dark-900);
  border-radius: var(--rounded-corner-small);
  padding: 1rem;
  margin-bottom: 2rem;
}

.game-invite-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.room-code-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

h2 {
  margin: 0;
}

p {
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

p.room-code {
  background: var(--dark-700);
  border-radius: var(--rounded-corner-small);
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 0 1rem 0 0;
  padding: 1rem;
  color: var(--light-white);
}

.code-nato {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--light-300);
}

.copy-code {
  flex: 0 1 auto;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;
  margin: 0;
}
</style>
