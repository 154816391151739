<template>
  <div class="game-chat-container">
    <div class="chat-heading">
      <h3>Chat</h3>
    </div>
    <div class="chat-messages-container" ref="chatContainer">
      <ul>
        <li v-for="chatMessage in getChatMessages" :key="chatMessage.timestamp">
          <span>{{ chatMessage.username }}</span
          >: {{ chatMessage.message }}
        </li>
      </ul>
    </div>
    <form class="chat-input-container" @submit.prevent="submitChatMessage">
      <base-input
        mode="inline"
        id="chatInput"
        type="text"
        placeholder="Chat message"
        labelMode="hidden"
        v-model="chatInput"
      ></base-input>
      <base-button mode="right-of-input">Send</base-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      chatInput: ''
    };
  },
  computed: {
    ...mapGetters(['getChatMessages', 'getUsername', 'getCurrentRoom'])
  },
  updated() {
    this.$nextTick(() => this.scrollToLatest());
  },
  methods: {
    ...mapActions(['addChatMessage']),
    submitChatMessage() {
      if (this.chatInput && this.chatInput.replace(/\s/g, '').length) {
        const currentTimeStamp = new Date();
        this.$socket.client.emit(
          'send_chat',
          this.getCurrentRoom,
          this.getUsername,
          this.chatInput,
          currentTimeStamp
        );
        this.chatInput = '';
      }
    },
    scrollToLatest() {
      let container = this.$refs.chatContainer;
      container.scrollTop = container.scrollHeight;
    }
  }
};
</script>

<style scoped>
.game-chat-container {
  grid-area: chat;
  background: var(--dark-600);
  border-bottom-right-radius: var(--rounded-corner-small);
  padding: 1rem;
  position: relative;
  min-height: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1fr 0.1fr;
  grid-template-areas:
    'chatheading'
    'chatmessages'
    'chatinput';
}

.chat-heading {
  grid-area: chatheading;
}

.chat-messages-container {
  grid-area: chatmessages;
  /* height: 25vh; */
  overflow-y: auto;
}

.chat-input-container {
  grid-area: chatinput;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.chat-input-container .form-control {
  flex: 1;
  margin: 0;
}

.chat-input-container button {
  flex: 0;
  margin: 0;
  padding: 1rem 2rem;
}

ul {
  list-style: none;
  margin: 0 0 2rem 0;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

li span {
  font-weight: 900;
}

.chat-messages-container::-webkit-scrollbar {
  width: 1rem;
}

.chat-messages-container::-webkit-scrollbar-track {
  border-radius: var(--rounded-corner-medium);
  background: var(--dark-500);
}

.chat-messages-container::-webkit-scrollbar-thumb {
  background-color: var(--dark-700);
  border-radius: var(--rounded-corner-medium);
  border: 3px solid var(--dark-500);
}
</style>
