<template>
  <section>
    <div class="container">
      <div class="content">
        <h2>Feedback</h2>
        <p>You can get in touch with me via <a href="https://twitter.com/josephlavington">Twitter</a>.</p>
      </div>
    </div>
  </section>
</template>
