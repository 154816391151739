<template>
  <div class="sidebar">
    <transition name="backdrop">
      <div
        class="sidebar-backdrop"
        @click="closeSidebarPanel"
        v-if="isPanelOpen"
      ></div>
    </transition>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    isPanelOpen() {
      return this.$store.getters.isNavOpen;
    }
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit('TOGGLE_NAV');
    }
  }
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 11;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: var(--dark-700);
  border-top-left-radius: 20px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 99;
  padding: 6rem 20px 2rem 20px;
  width: 300px;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

.backdrop-enter-active {
  transition: all 0.1s ease-out;
}

.backdrop-leave-active {
  transition: all 0.1s ease-in;
}

.backdrop-enter-to,
.backdrop-leave-from {
  opacity: 1;
}
</style>
