<template>
  <div class="brush-options">
    <div class="brush-size">
      <div
        class="brush-size-option"
        :class="{ active: getBrush.size == 6 }"
        @click="brushSize(6)"
      >
        <span
          class="brush-size-6"
          :style="{ background: getBrush.colour }"
        ></span>
      </div>
      <div
        class="brush-size-option"
        :class="{ active: getBrush.size == 12 }"
        @click="brushSize(12)"
      >
        <span
          class="brush-size-12"
          :style="{ background: getBrush.colour }"
        ></span>
      </div>
      <div
        class="brush-size-option"
        :class="{ active: getBrush.size == 24 }"
        @click="brushSize(24)"
      >
        <span
          class="brush-size-24"
          :style="{ background: getBrush.colour }"
        ></span>
      </div>
      <div
        class="brush-size-option"
        :class="{ active: getBrush.size == 48 }"
        @click="brushSize(48)"
      >
        <span
          class="brush-size-48"
          :style="{ background: getBrush.colour }"
        ></span>
      </div>
    </div>
    <div class="brush-undo">
      <span @click="undo">
        <font-awesome-icon icon="undo-alt" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  emits: ['undo'],
  computed: {
    ...mapGetters([
      'getBrush',
      'getPlayerActive',
      'getCurrentRoom',
      'getUndoLimit',
      'getBrushUndo'
    ]),
    getActivePlayer() {
      if (
        this.getPlayerActive &&
        this.getPlayerActive.id === this.$socket.client.id
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions(['setBrushSize', 'setUndoLimit', 'setBrushOneChance']),
    brushSize(size) {
      this.setBrushSize(size);
    },
    undo() {
      if (
        this.getActivePlayer &&
        !this.getUndoLimit &&
        this.getBrushUndo.length !== 0
      ) {
        this.setUndoLimit(true);
        this.setBrushOneChance(false);
        this.$socket.client.emit('undo_brush', this.getCurrentRoom);
      }
    }
  }
};
</script>

<style scoped>
.brush-options {
  grid-area: brushcontrols;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.brush-size {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.brush-undo {
  padding-left: 1rem;
  border-left: 2px solid var(--dark-500);
}

.brush-undo span {
  cursor: pointer;
}

.brush-undo span:hover {
  color: var(--light-white);
}

.brush-size-option {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--dark-500);
  background: var(--dark-500);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brush-size-option:hover {
  border: 2px solid var(--dark-600);
}

.brush-size-option span {
  width: 1rem;
  height: 1rem;
  display: block;
  background: #ffffff;
  border-radius: 50%;
  border: 2px solid var(--dark-500);
}

.brush-size-option span.brush-size-6 {
  width: 0.5rem;
  height: 0.5rem;
}
.brush-size-option span.brush-size-12 {
  width: 0.75rem;
  height: 0.75rem;
}
.brush-size-option span.brush-size-24 {
  width: 1rem;
  height: 1rem;
}
.brush-size-option span.brush-size-48 {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0;
}

.brush-size-option.active {
  border: 2px solid var(--light-white);
}

.brush-size-option:last-child {
  margin-right: 0;
}

p {
  margin: 0;
}

@media (max-width: 480px) {
  .brush-options {
    justify-content: center;
  }
}
</style>
