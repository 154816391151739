<template>
  <div v-if="isPlayerSecretArtist" class="current-word-container">
    <p>You are the</p>
    <p class="secret-artist">Secret Artist</p>
  </div>
  <div v-else class="current-word-container">
    <p>Current word</p>
    <p class="current-word">{{ getCurrentWord.name }}</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import words from '../../words.js';

export default {
  computed: {
    ...mapGetters([
      'getSecretArtist',
      'getCurrentWord',
      'getRoomAdmin',
      'getCurrentRoom',
      'getCustomWords',
      'getUseCustomWordsOnly'
    ]),
    isPlayerSecretArtist() {
      if (
        this.getSecretArtist &&
        this.getSecretArtist.id === this.$socket.client.id
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions(['setCurrentWord']),
    generateWord() {
      let wordsPayload = [];
      let generatedWord;

      if (this.getCustomWords.length > 0 && this.getUseCustomWordsOnly) {
        const customWordsList = [];
        for (let i = 0; i < this.getCustomWords.length; i++) {
          wordsPayload[i] = {
            name: this.getCustomWords[i].trim(),
            alternativeAnswers: ''
          };
          customWordsList.push(wordsPayload[i]);
        }
        generatedWord =
          customWordsList[Math.floor(Math.random() * customWordsList.length)];
        this.$socket.client.emit(
          'set_new_word',
          generatedWord,
          this.getCurrentRoom
        );
        return;
      } else if (this.getCustomWords.length > 0) {
        for (let i = 0; i < this.getCustomWords.length; i++) {
          wordsPayload[i] = {
            name: this.getCustomWords[i].trim(),
            alternativeAnswers: ''
          };
          words.push(wordsPayload[i]);
        }
      }

      generatedWord = words[Math.floor(Math.random() * words.length)];

      this.$socket.client.emit(
        'set_new_word',
        generatedWord,
        this.getCurrentRoom
      );
    }
  },
  created() {
    if (this.getRoomAdmin) {
      this.generateWord();
    }
  }
};
</script>

<style scoped>
.current-word-container {
  padding: 1rem;
  grid-area: currentword;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.current-word-container p {
  margin-bottom: 0;
}

p.current-word,
p.secret-artist {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

p.secret-artist {
  color: var(--primary-red);
}
</style>
