<template>
  <div v-for="item in displayList" :key="item.id" class="accordion">
    <header class="accordion-heading" @click="headerClicked">
      <h3>{{ item.heading }}</h3>
      <span><font-awesome-icon icon="caret-down"/></span>
    </header>
    <div class="panel">
      {{ item.body }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      displayList: this.list
    };
  },
  methods: {
    headerClicked(event) {
      event.currentTarget.classList.toggle('active');
      let panel = event.currentTarget.nextElementSibling;
      panel.classList.toggle('active');
    }
  }
};
</script>

<style scoped>
.accordion-heading {
  background: var(--dark-700);
  border-radius: var(--rounded-corner-small);
  cursor: pointer;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  margin: 0;
}

.accordion-heading span {
  font-size: 1.5rem;
  transition: all 0.2s ease-out;
}

.accordion-heading.active,
.accordion-heading:hover {
  background: var(--dark-600);
}

.accordion-heading.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-heading.active span {
  transform: rotate(180deg);
}

.panel {
  font-size: 1.25rem;
  background: var(--dark-700);
  border-bottom-left-radius: var(--rounded-corner-small);
  border-bottom-right-radius: var(--rounded-corner-small);
  padding: 0 2rem;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.1s ease-out;
}

.panel.active {
  max-height: max-content;
  padding: 2rem;
}
</style>
