<template>
  <div class="end-turn-container">
    <base-button v-if="getActivePlayer && !getVotingRound && !getWinState.flag" @click="endTurn" class="end-turn"
      ><font-awesome-icon icon="step-forward" /> End turn</base-button
    >
    <p v-else-if="!getVotingRound && !getWinState.flag">It's {{ getActivePlayerName }}'s turn to draw.</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getPlayers',
      'getCurrentRoom',
      'getVotingRoundGrace',
      'getPlayerActive',
      'getVotingRound',
      'getWinState'
    ]),
    getActivePlayer() {
      if (
        this.getPlayerActive &&
        this.getPlayerActive.id === this.$socket.client.id
      ) {
        return true;
      }
      return false;
    },
    getActivePlayerName() {
      if (this.getPlayerActive) {
        return this.getPlayerActive.username;
      }
      return false;
    }
  },
  methods: {
    ...mapActions(['setBrushOneChance', 'setUndoLimit', 'resetBrushUndo']),
    endTurn() {
      this.setBrushOneChance(false);
      this.setUndoLimit(false);
      this.resetBrushUndo();

      let findCurrentPlayer = this.getPlayers.findIndex(
        u => u.id === this.$socket.client.id
      );
      let nextPlayerIndex;

      if (findCurrentPlayer < this.getPlayers.length - 1) {
        findCurrentPlayer++;
        nextPlayerIndex = findCurrentPlayer;

        this.$socket.client.emit(
          'end_turn',
          this.getCurrentRoom,
          this.getPlayers,
          nextPlayerIndex
        );
      } else if (
        this.getVotingRoundGrace > 1 &&
        findCurrentPlayer === this.getPlayers.length - 1
      ) {
        findCurrentPlayer = 0;
        nextPlayerIndex = findCurrentPlayer;

        this.$socket.client.emit(
          'end_round',
          this.getCurrentRoom,
          this.getPlayers,
          nextPlayerIndex,
          true
        );
      } else {
        findCurrentPlayer = 0;
        nextPlayerIndex = findCurrentPlayer;

        this.$socket.client.emit(
          'end_round',
          this.getCurrentRoom,
          this.getPlayers,
          nextPlayerIndex,
          false
        );
      }
    }
  }
};
</script>

<style scoped>
.end-turn-container {
    grid-area: endturn;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.end-turn-container button {
    margin: 0;
}

p {
  margin: 0;
}

@media (max-width: 480px) {
    .end-turn-container {
        justify-content: center;
    }
}
</style>
