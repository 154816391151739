<template>
  <section>
    <base-dialog
      :show="getWinState.flag"
      :title="
        getWinState.result === 'secretartist'
          ? 'Secret Artist wins!'
          : 'Players win!'
      "
      @close="handleWinner"
      ><div v-if="getWinState.result === 'secretartist'">
        <p>
          The Secret Artist - {{ calculateWinner }} - correctly guessed the word
          and has won the game!
        </p>
      </div>
      <div v-else-if="getWinState.result === 'otherplayers'">
        <p>The players have won the game!</p>
        <p>
          The Secret Artist - {{ calculateWinner }} - did not correctly guess
          the word.
        </p>
        <p v-if="getWinState.guess">Their guess was: {{ getWinState.guess }}</p>
        <p v-else>They didn't enter a guess.</p>
      </div>
      <template v-slot:actions>
        <base-button mode="green" @click="handleWinner"
          >Return to Lobby</base-button
        ></template
      ></base-dialog
    >

    <base-dialog
      :show="getGlobalError.flag"
      title="Disconnection"
      @close="handleGlobalError"
      mode="error"
      >{{ getGlobalError.type }}. Please return to the welcome screen to start a
      new game.
      <template v-slot:actions>
        <base-button mode="red" @click="handleGlobalError"
          >Leave Game</base-button
        ></template
      ></base-dialog
    >

    <base-dialog :show="getKicked" title="Kicked" @close="handleKicked">
      Sorry, you were kicked from this room.
      <template v-slot:actions>
        <base-button mode="red" @click="handleKicked"
          >Leave Game</base-button
        ></template
      ></base-dialog
    >

    <game-vote
      :show="getVotingRound"
      title="Who is the Secret Artist?"
    ></game-vote>

    <div class="room-container">
      <game-canvas></game-canvas>
      <brush-controls></brush-controls>
      <game-end-turn></game-end-turn>
      <game-word></game-word>
      <game-players></game-players>
      <game-chat></game-chat>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GamePlayers from '../components/game/GamePlayers.vue';
import GameChat from '../components/game/GameChat.vue';
import GameCanvas from '../components/game/GameCanvas.vue';
import GameVote from '../components/game/GameVote.vue';
import GameWord from '../components/game/GameWord.vue';
import BrushControls from '../components/ui/BrushControls.vue';
import GameEndTurn from '../components/game/GameEndTurn.vue';

export default {
  components: {
    GamePlayers,
    GameChat,
    GameCanvas,
    GameVote,
    GameWord,
    BrushControls,
    GameEndTurn
  },
  data() {
    return {
      winNotification: false
    };
  },
  watch: {
    getKicked() {
      if (this.getKicked === true) {
        this.$socket.client.emit('LEAVE_GAME');
        this.$router.replace({ name: 'home' });
      }
    },
    getPlayers(value) {
      if (value.length < 3) {
        this.$socket.client.emit(
          'less_than_three_players',
          this.getCurrentRoom
        );
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentRoom',
      'getPlayers',
      'getGameStage',
      'getVotingRound',
      'getRoomAdmin',
      'getWinState',
      'getGlobalError',
      'getKicked'
    ]),
    calculateWinner() {
      if (this.getWinState.flag) {
        const secretArtistPlayer = this.getPlayers.find(
          p => p.secretArtist === true
        );
        const secretArtistName = secretArtistPlayer.username;
        return secretArtistName;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(['setVotingRound']),
    handleGlobalError() {
      this.$socket.client.emit('LEAVE_GAME');
      this.$router.replace({ name: 'home' });
    },
    handleWinner() {
      if (this.getRoomAdmin) {
        this.$socket.client.emit('return_to_lobby', this.getCurrentRoom);
      }
      this.$router.replace({ name: 'lobby' });
    },
    handleKicked() {
      this.$router.replace({ name: 'home' });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'lobby') {
      next();
    } else {
      next({ name: 'home' });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'lobby' && !this.getGlobalError.flag) {
      const answer = window.confirm('Do you really want to leave the game?');
      if (answer) {
        this.$socket.client.emit('LEAVE_GAME');
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.container {
  align-items: flex-start;
}

.room-container {
  display: grid;
  width: 94vw;
  max-width: 1440px;
  margin: 2.5rem auto 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 1fr 1fr;
  /* grid-template-rows: minmax(min-content, 1fr); */
  grid-template-areas:
    'brushcontrols endturn currentword'
    'canvas canvas players'
    'canvas canvas chat';
  scrollbar-width: thin;
  scrollbar-color: var(--dark-700) var(--dark-900);
}

dialog section p:last-child {
  margin: 0;
}

@media (max-width: 768px) {
  .room-container {
    margin: 0 auto;
    height: auto;
    width: 96vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 1fr 0.2fr 0.2fr 1fr;
    grid-template-areas:
      'players players'
      'canvas canvas'
      'brushcontrols endturn'
      'currentword currentword'
      'chat chat';
  }
}

@media (max-width: 480px) {
  .room-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1fr 0.2fr 0.2fr 0.2fr 1fr;
    grid-template-areas:
      'players'
      'canvas'
      'brushcontrols'
      'endturn'
      'currentword'
      'chat';
  }
}
</style>
