<template>
  <div class="game-players-container">
    <div class="players-heading">
      <h3>Players</h3>
    </div>
    <div class="player-list-container">
      <ul class="player-list">
        <li
          v-for="player in getPlayers"
          :key="player.id"
          :class="[
            { admin: player.admin === true, active: player.active === true }
          ]"
        >
          <div class="player-icon">
            <span
              class="player-colour"
              :class="{ active: player.active === true }"
              :style="{ background: player.colour }"
              ><font-awesome-icon
                v-if="player.active === true"
                icon="paint-brush"
            /></span>
          </div>
          <div class="player-username">{{ player.username }}</div>
          <div class="player-admin">
            <span
              v-if="player.admin === true"
              class="host-icon"
              data-tooltip="Host"
              ><font-awesome-icon icon="crown"
            /></span>
          </div>
          <div class="player-kick" v-if="getRoomAdmin">
            <span
              v-if="player.admin !== true"
              class="kick-icon"
              data-tooltip="Kick player"
              @click="kickPlayer(player.id)"
              ><font-awesome-icon icon="times-circle"
            /></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getPlayers',
      'getRoomAdmin',
      'getGameStage',
      'getCurrentRoom'
    ])
  },
  methods: {
    kickPlayer(playerId) {
      this.$socket.client.emit('kick_player', playerId);
    }
  }
};
</script>

<style scoped>
.game-players-container {
  grid-area: players;
  background: var(--dark-700);
  border-top-right-radius: var(--rounded-corner-small);
  padding: 1rem;
  color: var(--light-white);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1fr 0.1fr;
  grid-template-areas:
    'playersheading'
    'playerslist';
  align-items: unset;
}

.players-heading {
  grid-area: playersheading;
  position: relative;
}

h3 {
  margin-bottom: 0.5rem;
}

.player-list-container {
  grid-area: playerslist;
  overflow-y: auto;
}

.player-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2rem;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: var(--rounded-corner-small);
  background: var(--dark-600);
}

.active {
  background: var(--dark-500);
}

.player-username {
  flex: 1;
  word-break: break-word;
}

.player-colour {
  display: block;
  border-radius: 50%;
  border: 3px solid var(--dark-400);
  width: 1.6rem;
  height: 1.6rem;
  background: var(--primary-red);
  margin-right: 0.6rem;
  font-size: 1rem;
  line-height: 1.8rem;
  text-align: center;
}

.player-colour.active {
  border: 3px solid var(--light-white);
}

.player-active {
  margin-left: 0.5rem;
}

.host-icon,
.kick-icon {
  position: relative;
}

.kick-icon {
  cursor: pointer;
  color: var(--dark-400);
}

.kick-icon:hover {
  color: var(--light-white);
}

.host-icon::before,
.kick-icon::before {
  --scale: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-110%) translateY(-12.5%) scale(var(--scale));
  transition: 50ms transform;
  transform-origin: right center;
  content: attr(data-tooltip);
  padding: 0.25rem 0.5rem;
  width: max-content;
  background: var(--dark-700);
  border-radius: var(--rounded-corner-small);
  text-align: center;
  color: var(--light-white);
}

li.admin:hover .host-icon::before,
.kick-icon:hover::before {
  --scale: 1;
}

.player-admin {
  margin-left: 0.5rem;
}

.player-list-container::-webkit-scrollbar {
  width: 1rem;
}

.player-list-container::-webkit-scrollbar-track {
  border-radius: var(--rounded-corner-medium);
  background: var(--dark-500);
}

.player-list-container::-webkit-scrollbar-thumb {
  background-color: var(--dark-700);
  border-radius: var(--rounded-corner-medium);
  border: 3px solid var(--dark-500);
}

@media (max-width: 768px) {
  .game-players-container {
    align-items: center;
  }
  .h3 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .player-list {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 1.25rem;
  }
  li {
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    min-width: 4rem;
    max-width: 4rem;
  }
  .player-colour {
    margin: 0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }
  .player-admin {
    margin: 0;
  }
}
</style>
