import { createRouter, createWebHistory } from 'vue-router';

import NotFound from './pages/NotFound.vue';
import GameWelcome from './pages/GameWelcome.vue';
import GameAbout from './pages/GameAbout.vue';
import GameLobby from './pages/GameLobby.vue';
import GameRoom from './pages/GameRoom.vue';
import GameHelp from './pages/GameHelp.vue';
import GameFeedback from './pages/GameFeedback.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'home', component: GameWelcome },
    {
      path: '/lobby/:id([A-Z]{4})',
      name: 'lobby',
      component: GameLobby
    },
    {
      path: '/room/:id([A-Z]{4})',
      name: 'room',
      component: GameRoom
    },
    { path: '/about', name: 'about', component: GameAbout },
    { path: '/help', name: 'help', component: GameHelp },
    { path: '/feedback', name: 'feedback', component: GameFeedback },
    { path: '/:notFound(.*)*', name: 'notfound', component: NotFound }
  ]
});

export default router;
