<template>
  <div class="loading-spinner">
    <font-awesome-icon icon="palette" spin size="3x" />
    <font-awesome-icon icon="paint-brush" size="lg" class="paint-brush" />
  </div>
</template>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paint-brush {
  margin-left: 0.5rem;
}
</style>
