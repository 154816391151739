<template>
  <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>

<script>
export default {
  computed: {
    isBurgerActive() {
      return this.$store.getters.isNavOpen;
    },
  },
  methods: {
    toggle() {
      this.$store.commit('TOGGLE_NAV');
    }
  }
};
</script>

<style>
.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 60px;
  width: 60px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: var(--rounded-corner-small);
  background-color: var(--dark-600);
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: var(--light-white);
  position: absolute;
  top: 50%;
  right: 12px;
  left: 12px;
  height: 5px;
  width: auto;
  margin-top: -2px;
  border-radius: 5px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-11px);
  transform: translateY(-11px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(1);
}

.burger-button:hover .burger-bar--2 {
  transform: scaleX(0.8);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(0.8);
}

.burger-bar--3 {
  transform: translateY(11px);
}

#burger.active .burger-button {
  background: var(--dark-500);
}

#burger.active .burger-bar {
  background-color: var(--light-white);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}
</style>
