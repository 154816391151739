<template>
  <div class="game-canvas-container">
    <canvas
      id="canvas"
      ref="canvas"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @mousemove="mouseMove"
      @mouseleave="mouseLeave"
      @touchstart="mouseDown"
      @touchend="mouseUp"
      @touchcancel="mouseUp"
      @touchmove="mouseMove"
      width="100%"
      height="100%"
      :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
    ></canvas>
    <div class="cursor" ref="cursor" v-if="getActivePlayer"></div>
    <div class="cursor inactive" ref="cursor" v-if="!getActivePlayer"></div>
    <!-- <base-button @click="notificationTest">Show notification</base-button> -->

    <game-notification
      :show="showNotification"
      :title="notificationTitle"
    ></game-notification>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GameNotification from './GameNotification.vue';

export default {
  components: {
    GameNotification
  },
  data() {
    return {
      showNotification: false,
      notificationTitle: 'Notification',
      x: 0,
      y: 0,
      canvas: null,
      context: null,
      canvasWidth: null,
      canvasHeight: null,
      drawing: false
    };
  },
  sockets: {
    drawing(data) {
      this.onDrawingEvent(data);
    },
    drawing_mouse_up() {
      this.setBrushHistory(this.getBrushUndo);
    },
    next_player_turn() {
      this.drawing = false;
      this.resetBrushUndo();
      this.setUndoLimit(false);
    },
    undo_brush() {
      this.undoBrushHistory();
      this.resetBrushUndo();
      this.redraw();
    }
  },
  computed: {
    ...mapGetters([
      'getBrush',
      'getBrushHistory',
      'getBrushUndo',
      'getPlayers',
      'getCurrentRoom',
      'getPlayerActive',
      'getSecretArtistFound',
      'getVotingRoundGrace',
      'getUndoLimit',
      'getBrushOneChance'
    ]),
    getActivePlayer() {
      if (
        this.getPlayerActive &&
        this.getPlayerActive.id === this.$socket.client.id
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'setBrushColour',
      'setBrushHistory',
      'undoBrushHistory',
      'setBrushUndo',
      'resetBrushUndo',
      'setUndoLimit',
      'setBrushOneChance'
    ]),
    getMousePosition(event) {
      const rect = this.canvas.getBoundingClientRect();

      let w = this.canvas.width;
      let h = this.canvas.height;

      const x = ((event.clientX || event.touches[0].clientX) - rect.left) / w;
      const y = ((event.clientY || event.touches[0].clientY) - rect.top) / h;
      return { x, y };
    },
    mouseDown(event) {
      if (!this.getBrushOneChance) {
        let mousePosition = this.getMousePosition(event);
        this.drawing = true;
        this.setUndoLimit(false);
        this.resetBrushUndo();
        this.x = mousePosition.x;
        this.y = mousePosition.y;
      }
    },
    mouseMove(event) {
      this.cursor(event);

      if (!this.drawing || !this.getActivePlayer) {
        return;
      }

      let mousePosition = this.getMousePosition(event);

      this.setBrushUndo({
        x0: this.x,
        y0: this.y,
        x1: mousePosition.x,
        y1: mousePosition.y,
        size: this.getBrush.size,
        colour: this.getBrush.colour
      });

      this.drawLine(
        this.x,
        this.y,
        mousePosition.x,
        mousePosition.y,
        this.getBrush.size,
        this.getBrush.colour,
        true
      );
      this.x = mousePosition.x;
      this.y = mousePosition.y;
    },
    mouseUp() {
      if (!this.drawing || !this.getActivePlayer) {
        return;
      }
      this.drawing = false;
      this.setBrushOneChance(true);

      this.$socket.client.emit('drawing_mouse_up', this.getCurrentRoom);
      this.setBrushHistory(this.getBrushUndo);
    },
    mouseLeave() {
      let cursor = this.$refs.cursor;
      cursor.style.opacity = 0;

      this.mouseUp();
    },
    drawLine(x0, y0, x1, y1, size, colour, emit) {
      let w = this.canvas.width;
      let h = this.canvas.height;

      this.context.lineCap = 'round';
      this.context.lineJoin = 'round';
      this.context.strokeStyle = colour;
      this.context.lineWidth = size;

      this.context.beginPath();
      this.context.moveTo(x0 * w, y0 * h);
      this.context.lineTo(x1 * w, y1 * h);
      this.context.stroke();
      this.context.closePath();

      if (!emit) {
        return;
      }

      this.$socket.client.emit(
        'drawing',
        {
          x0: x0,
          y0: y0,
          x1: x1,
          y1: y1,
          size: size,
          colour: colour
        },
        this.getCurrentRoom
      );
    },
    onDrawingEvent(data) {
      this.drawLine(data.x0, data.y0, data.x1, data.y1, data.size, data.colour);

      this.setBrushUndo({
        x0: data.x0,
        y0: data.y0,
        x1: data.x1,
        y1: data.y1,
        size: data.size,
        colour: data.colour
      });
    },
    onResize() {
      let rect = this.canvas.parentNode.getBoundingClientRect();
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;

      this.canvasWidth = this.canvas.width;
      this.canvasHeight = this.canvas.height;
    },
    redraw() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (!this.getBrushHistory.length) return true;

      this.getBrushHistory.forEach(path => {
        for (let i = 0; i < path.length; i++) {
          this.drawLine(
            path[i].x0,
            path[i].y0,
            path[i].x1,
            path[i].y1,
            path[i].size,
            path[i].colour
          );
        }
      });
    },
    resetCanvas() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    notificationTest() {
      this.showNotification = !this.showNotification;
    },
    cursor(event) {
      let x = event.clientX;
      let y = event.clientY;

      let cursor = this.$refs.cursor;
      cursor.style.transform = `translate(${x - 11}px, ${y - 11}px)`;
      cursor.style.opacity = 1;
    },
    calculateBrushColour() {
      let thisPlayerColour = this.getPlayers.find(
        p => p.id == this.$socket.client.id
      ).colour;
      this.setBrushColour(thisPlayerColour);
    }
  },
  created() {
    this.calculateBrushColour();
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.context = this.$refs.canvas.getContext('2d');

    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;

    window.addEventListener('resize', () => {
      this.onResize();
      this.$nextTick(() => {
        this.redraw();
      });
    });
    this.onResize();
    this.redraw();
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.onResize();
      this.$nextTick(() => {
        this.redraw();
      });
    });
  }
};
</script>

<style scoped>
.game-canvas-container {
  grid-area: canvas;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 4 / 3;
}

#canvas {
  background: var(--dark-500);
  border-top-left-radius: var(--rounded-corner-small);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-corner-small);
  border-bottom-right-radius: 0;
  cursor: none;
  touch-action: none;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 3px solid var(--light-white);
  pointer-events: none;
  user-select: none;
  mix-blend-mode: difference;
  opacity: 0;
  transition: opacity 0.25s;
}

.cursor.inactive {
  border-radius: 0;
  border: 0;
  /* opacity: 1; */
}
.cursor.inactive::before,
.cursor.inactive::after {
  position: absolute;
  left: 0;
  content: '';
  height: 1.5rem;
  width: 0.2rem;
  background: var(--light-white);
}
.cursor.inactive::before {
  transform: rotate(45deg);
}
.cursor.inactive::after {
  transform: rotate(-45deg);
}

@media (max-width: 768px) {
  #canvas {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--rounded-corner-small);
    border-bottom-right-radius: var(--rounded-corner-small);
  }
}
</style>
