<template>
  <header>
    <div class="header--container">
      <div class="game-title">
        <h1>
          <router-link to="/" class="underline">Secret Artist</router-link>
        </h1>
      </div>

      <div class="header-right">
        <base-burger
          class="burger-button"
          v-if="getGameStage === 'welcome'"
        ></base-burger>

        <base-button
          v-else-if="getGameStage === 'lobby' || getGameStage === 'ingame'"
          mode="red"
          :link="true"
          to="/"
          >Leave <font-awesome-icon icon="sign-out-alt"
        /></base-button>
      </div>

      <nav role="navigation">
        <base-sidebar>
          <ul>
            <li @click="closeSidebarPanel">
              <router-link to="/">Home</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/about">About</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/help">Help</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/feedback">Feedback</router-link>
            </li>
          </ul>
        </base-sidebar>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getGameStage'])
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit('TOGGLE_NAV');
    },
    leaveGame() {
      this.$socket.client.emit('LEAVE_GAME');
      this.$router.replace({ name: 'home' });
    }
  }
};
</script>

<style scoped>
header {
  position: relative;
  padding: 0.75em;
  margin: 0;
  background-color: var(--dark-900);
  color: var(--light-white);
}

.header--container {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  justify-items: center;
  align-items: center;
}

.header-right {
  grid-column: 5 / -1;
  display: inline-block;
  margin-left: auto;
}
.header-right a {
  margin: 0;
}

ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0 0 0 2em;
}

ul li {
  margin: 0 0 2em 0;
}

nav a {
  color: var(--light-white);
  text-decoration: none;
  font-size: 2rem;
  font-family: 'kansasnew', serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
}

nav a:hover {
  text-decoration: none;
  color: var(--primary-green);
}

.game-title {
  grid-column: 2 / 3;
}

.game-title a {
  text-decoration: none;
  color: var(--light-white);
  position: relative;
  display: inline-block;
}

nav a:hover::after,
a.underline:hover::after {
  content: '';
  position: absolute;
  height: 32px;
  width: 100%;
  background-image: url(../../assets/img/brush-stroke-01.svg);
  background-size: cover;
  bottom: 5px;
  left: 0;
  border-radius: 50em;
  opacity: 0.75;
}

nav a:hover::after {
  background-image: url(../../assets/img/brush-stroke-02.svg);
  background-size: cover;
  height: 6px;
  bottom: 0px;
}

@media (max-width: 768px) {
  .header--container {
    justify-items: flex-start;
  }
  .game-title {
    grid-column: 1 / 2;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
}
</style>
